<fap-header>
  <div class="d-flex filters align-items-center">
      <div class="tree_combo mr-3">
        <fap-tree-combo></fap-tree-combo>
      </div>
      <div class="add_btn mr-3">
        <fap-round-button [backgroundColor]="'tertiary'" [routerLink]="'/pages/notes/add'"></fap-round-button>
      </div>
      <fap-search-filter
        [isSearchEnabled]="true"
>     </fap-search-filter>
    </div>
</fap-header>
<div
  class="container-fluid ter-container nc"
  #notesContainer
  style="flex: auto;"
>
<ng-container *ngIf="isLoading">
  <span class="loader">
      <i></i>
  </span>
</ng-container>
<ng-container *ngIf="!isLoading">
  <fap-notes-layout
    [farms]="filteredFarms"
    [persons]="globalRegistry?.systemData?.persons"
    [notes]="filteredNotes"
    [farmForecastMapping]="farmForecastMapping"
    [types]="noteTypes"
    (addNote)="addNote($event)"
    (deleteNote)="deleteNote($event)"
  >
  </fap-notes-layout>
  <span class="not_found" *ngIf="filteredNotes.length === 0">No notes found</span>
</ng-container>
</div>
<fap-pagination
  [count]="count"
  [pageSize]="limit"
  (emitNextPrev)="handlePagination($event)"
></fap-pagination>