import {Pipe,PipeTransform} from "@angular/core";
import { SensorModel } from '../models/sensor/sensor.model';

const unitTypes = {
    "K":272,
    "P":271,
    "N":270,
    "ec":58 ,
    "temperature": 10 ,
    "humidity": 20,
    "ph":1
} 

@Pipe({
    pure:false,
    name:"unit"
})
export class UnitPipe implements PipeTransform{
    public transform(items:SensorModel[],term:number){
        return items.filter(item=>item.sensorRef.unit_type===unitTypes[term]);
    }
}