import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewEncapsulation, Input
} from '@angular/core';
import {
    Router,
    ActivatedRoute
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../core/services/api/auth/auth.service';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { NavService, Menu } from '../../services/nav.service';
import { LotModel } from '../../../core/models/lot/lot.model';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { WidgetsService } from '../../../core/services/api/widgets/widgets.service';
import { UserService } from '../../../core/services/api/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';

@Component({
    selector: 'fap-header',
    templateUrl: './fap_header.component.html',
    styleUrls: ['./fap_header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FapHeaderComponent implements OnInit {

    public menuItems: Menu[];
    public items: Menu[];
    public openNav = false;
    public rightSidebar = false;
    public text: string;
    public language = 'en';
    
    public background: string;
    public buttonColor: string;
    public accentColor: string;
    public sidebarColor: string;
    public hoverColor: string;
    public farmAddMenu: boolean;
    public hoverPlantation: boolean;
    public hoverPark: boolean;
    public selectPlantations: boolean;
    public lotFilterValue: string = undefined;
        
    public toggleSidebar: boolean;
    public isCreateMod: boolean;
    public isEditMod = false;
    public isViewMod = false;
    public lotList: LotModel[] = [];
    
    public cancelTimeOut: boolean = false

    @Input()
    public isSelectDialogOpen: boolean;

    @Output()
    public rightSidebarEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public baseDialogEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public navServices: NavService,
                public router: Router,
                public activatedRoute: ActivatedRoute,
                public translate: TranslateService,
                public globalRegistry: GlobalRegistryService,
                public sensorService:SensorService,
                public authService: AuthService,
                public widgetsSerivce: WidgetsService,
                public userService: UserService,
                public toastrService: ToastrService,
                public cacheService: CacheResolverService,
                ) {
        translate.setDefaultLang(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
        this.navServices.isSideBarInitiallyOpened.subscribe(state => {
            if(state) {
                this.cancelTimeOut = true
            }
        })
        if(this.cancelTimeOut) {
            this.toggleSidebar = true
        } else {
            setTimeout(() => {
                this.navServices.collapseSidebar.next(true);
            },3000)
        }
        this.navServices.isSideBarInitiallyOpened.next(true);
    }

    public getToggleStatus() {
        this.navServices.collapseSidebar.subscribe((collapse: boolean) => {
            this.toggleSidebar = collapse;
        });
    }

    public ngOnInit(): void {
        this.navServices.getCurrentLanguage.subscribe(lang => {
            this.language = lang;
        })
        this.getToggleStatus()
        this.userService.getCurrentUser.subscribe((user) => {
            if (Object.keys(user).length != 0) {
                this.userService.setTimeZone(user.profile.data.timezone);
            }
        })
    }

    public collapseSidebar(): void {
        this.navServices.collapseSidebar.next(!this.navServices.collapseSidebar);
    }

    public openSecondNav(): void {
        this.openNav = !this.openNav;
    }

    public changeLanguage(lang: string): void {
        this.translate.use(lang);
        localStorage.setItem('language', lang);
        this.navServices.setCurrentLanguage(lang);
        this.language = lang ? lang : 'en';
        location.reload();
    }

    public logOut(): void {
        this.cacheService.clearAllCache();
        this.authService.logout().subscribe(data => {
            console.log(data);
            const countryData = localStorage.getItem('country_info');
            const language = localStorage.getItem('language');
            localStorage.clear();
            localStorage.setItem('country_info', countryData);
            localStorage.setItem('language', language);
            this.globalRegistry.isLoggedIn = false;
        });
        this.router.navigate(['login']);
    }

}
