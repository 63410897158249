import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import moment from 'moment';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../services/nav.service';
import { FapModalComponent } from '../../fap-modal/fap-modal.component';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { ToastrService } from 'ngx-toastr';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'fap-add-edit-scheduler',
  templateUrl: './fap-add-edit-scheduler.component.html',
  styleUrls: ['./fap-add-edit-scheduler.component.scss']
})
export class FapAddEditSchedulerComponent implements OnInit, OnChanges, OnDestroy{

  @Input() public scheduler = null;
  @Input() public schedulerId = null;
  @Output() public closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() public addScheduler: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updateScheduler: EventEmitter<any> = new EventEmitter<any>();
  public addEditSchedulerForm: FormGroup;
  public schedulerTranslation = null;
  public langString: string = 'en';
  public subscriptions: Subscription[] = [];
  @ViewChild('addEditSchedulerTranslationPopup')
  public addEditSchedulerTranslationPopup: FapModalComponent;
  checkboxControls: { [key: string]: FormControl } = {};
  public minDate = null;
  public maxDate = null;
  public availableDays = Array.from({ length: 7 }, (_, i) => ({
    id: (i + 1).toString(),
    name: new Date(1970, 0, i + 4).toLocaleString('en-US', { weekday: 'short' })
  }));
  public availableMonths = Array.from({ length: 12 }, (_, i) => ({
    id: (i + 1).toString(),
    name: new Date(1970, i, 1).toLocaleString('en-US', { month: 'short' })
  }));
  public availableDates = Array.from({ length: 31 }, (_, i) => ({
    id: (i + 1).toString(),
    name: (i + 1).toString()
  }));
  public availableHours = Array.from({ length: 24 }, (_, i) => ({
    id: (i + 1).toString(), // Starts from 01
    name: (i + 1).toString().padStart(2, '0')
  }));
  public availableMinutes = Array.from({ length: 12 }, (_, i) => {
    const minutes = i * 5; // Starts from 0
    return {
      id: minutes.toString(),
      name: minutes.toString().padStart(2, '0') // Pads single-digit minutes with '0'
    };
  });
  
  public days = [];
  public dates = [];
  public translation = null;
  public label:string;
  public modifiedScheduler = null;
  public month = [];
  public day = [];
  public dweek = [];
  public hour = [];
  public minute = [];
  @ViewChild('nameInput2', { static: true }) nameInput2: ElementRef;

  @ViewChild('matrixModal') matrixModal: FapModalComponent
  public matrixType: string;
  public matrixItems: any = [];
  public selectedItems: any;

  constructor(private navService: NavService, private formBuilder: FormBuilder, public globalRegistry: GlobalRegistryService, public sensorService: SensorService, public toastr: ToastrService) {
    for (let i = 1; i <= 31; i++) {
      this.dates.push(i.toString());
    }
  }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.initAddEditSchedulerForm()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('schedulerId') && this.schedulerId === 0) {
      this.scheduler = null;
      this.modifiedScheduler = null;
      this.addEditSchedulerForm.reset();
      this.addEditSchedulerForm.clearValidators();
      this.initAddEditSchedulerForm();
      this.dweek = [];
      this.availableDays.forEach((day) => {
        const control = new FormControl(this.dweek.includes(day.id));
        this.checkboxControls[day.id] = control;
      });
    }
    if (changes.hasOwnProperty('scheduler') && this.scheduler && this.schedulerId !== 0) {
        this.modifiedScheduler = { ...this.scheduler };
                                       
        if(this.modifiedScheduler.dweek) {
          if(this.modifiedScheduler.dweek === '*') {
            this.dweek = [];
            for (let i = 1; i <= 7; i++) {
              this.dweek.push(i.toString());
            }
            this.modifiedScheduler.dweek = this.dweek;
          } else {
            this.dweek = this.modifiedScheduler.dweek.split(',');
          }
        }

        if(this.modifiedScheduler.month) {
          if(this.modifiedScheduler.month === '*') {
            this.month = [];
            for (let i = 1; i <= 12; i++) {
              this.month.push(i.toString());
            }
            this.modifiedScheduler.month = this.month;
          } else {
            this.month = this.modifiedScheduler.month.split(',');
          }
        }

        if(this.modifiedScheduler.day) {
          if(this.modifiedScheduler.day === '*') {
            this.day = [];
            for (let i = 1; i <= 31; i++) {
              this.day.push(i.toString());
            }
            this.modifiedScheduler.day = this.day;
          } else {
            this.day = this.modifiedScheduler.day.split(',');
          }
        }

        if(this.modifiedScheduler.hour) {
          if(this.modifiedScheduler.hour === '*') {
            this.hour = [];
            for (let i = 1; i <= 24; i++) {
              this.hour.push(i.toString());
            }
            this.modifiedScheduler.hour = this.hour;
          } else {
            this.hour = this.modifiedScheduler.hour.split(',');
          }
        }

        if (this.modifiedScheduler.minute) {
          if (this.modifiedScheduler.minute === '*') {
            this.minute = [];
            for (let i = 5; i <= 60; i += 5) { // Correct increment
              this.minute.push(i.toString());
            }
            this.modifiedScheduler.minute = this.minute;
          } else {
            this.minute = this.modifiedScheduler.minute.split(',');
          }
        }        

        this.initAddEditSchedulerForm();
        console.log(this.month);
        console.log(this.day);
        // this.addEditSchedulerForm.get('month').setValue(this.month);
        // this.addEditSchedulerForm.get('day').setValue(this.day);
    }
}

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter((trans) => {
        return trans.id === translation;
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation;
    }
  }

  maxValueChanged(ev) {
    console.log(ev);
  }

  minValueChanged(ev) {
    console.log(ev);
  }

  addEditTranslation(label: string, inputElement: any, translationTarget: any, popup: any, form: any) {
    this.label = label;
    const name = this[inputElement].nativeElement.value;
    const type: any = this.convertKeysToCamelCase(translationTarget);

    if (name === null || name === '' || type.nameT === null) {
        this.translation = null;
        popup.showModal();
    } else {
        for (const translation of this.globalRegistry.systemData.translations) {
            if (type && translation.id === type.nameT) {
                this.translation = translation;
                console.log(this.translation);
                popup.showModal();
                return;
            }
        }

        if (Object.keys(type).length === 0 && name) {
            const newTranslation = this[form].controls['name_t'].value;
            for (const translation of this.globalRegistry.systemData.translations) {
                if (translation.id === newTranslation) {
                    this.translation = translation;
                    console.log(this.translation);
                    popup.showModal();
                    return;
                }
            }
        }
    }
}        

convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
        const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) =>
            p1.toUpperCase()
        );
        camelCaseObj[camelCaseKey] =
            obj[key] instanceof Object
                ? this.convertKeysToCamelCase(obj[key])
                : obj[key];
    }
    return camelCaseObj;
}

  onAddUpdateSchedulerTranslation(translation) {
    console.log(translation);
    this.schedulerTranslation = translation;
    this.addEditSchedulerForm.controls['name_t'].setValue(
        translation.id
    );
    this.addEditSchedulerForm.controls['name'].setValue(
        translation[this.langString]
    );
    this.schedulerTranslation = translation;
    this.addEditSchedulerTranslationPopup.hideModal();
  }

  initAddEditSchedulerForm() {
    this.addEditSchedulerForm = this.formBuilder.group({
        name: [
            this.modifiedScheduler ? this.modifiedScheduler.name : '',
            Validators.required,
        ],
        name_t: [
            this.modifiedScheduler ? this.modifiedScheduler.name_t : '',
            Validators.required,
        ],
        // from: [
        //     this.modifiedScheduler ? this.modifiedScheduler.start : new Date(),
        //     Validators.required,
        // ],
        // to: [
        //     this.modifiedScheduler ? this.modifiedScheduler.end : new Date(),
        //     Validators.required,
        // ],
        dweek: [
          this.modifiedScheduler ? this.dweek : [],
          Validators.required,
      ],
        hour: [
            this.modifiedScheduler ? this.hour : [],
            Validators.required,
        ],
        month: [
          this.modifiedScheduler ? this.month : [],
          Validators.required,
        ],
        day: [
          this.modifiedScheduler ? this.day : [],
          Validators.required,
        ],
        minute: [
            this.modifiedScheduler ? this.minute : [],
            Validators.required,
        ],
        // duration: [
        //     this.modifiedScheduler ? this.modifiedScheduler.duration : '',
        //     Validators.required,
        // ]
    });
    if(this.modifiedScheduler) {
      if(this.modifiedScheduler.name_t) {
        this.globalRegistry.systemData.translations.forEach(
            (translation) => {
                if (translation.id === this.modifiedScheduler.name_t) {
                    this.schedulerTranslation = translation;
                }
            }
        );
        this.addEditSchedulerForm.get('name').setValue(this.getTranslation(this.modifiedScheduler.name_t))
    }
    }
  }

  submitScheduler() {
    
    const body = {
        name_t : this.schedulerTranslation ? this.schedulerTranslation.id : '',
        day: this.addEditSchedulerForm.value.day.toString(),
        hour: this.addEditSchedulerForm.value.hour.toString(),
        minute: this.addEditSchedulerForm.value.minute.toString(),
        dweek: this.addEditSchedulerForm.value.dweek.toString(),
        month: this.addEditSchedulerForm.value.month.toString()
    }
    console.log(body);
    if(this.modifiedScheduler) {
        this.sensorService.updateScheduler(this.modifiedScheduler.id, body).subscribe((data) => {
            console.log(data);
            this.updateScheduler.emit(data.body.results);
            this.toastr.success('Scheduler updated successfully');
        }, (error) => {
          this.handleErrorMessage(error)
        })
    } else {
        this.sensorService.createScheduler(body).subscribe((data) => {
            console.log(data);
            this.addScheduler.emit(data.body.results);
            this.toastr.success('Scheduler created successfully');
        }, (error) => {
          this.handleErrorMessage(error)
        })
    }
  }

  handleErrorMessage(error) {
    return this.toastr.error('['+ error.error.results.code +'] ', error.error.results.error);
  }

  changeDay(ev) {
    console.log(ev);
  }

  showMatrixModal(type: string, items: any[]) {
    this.matrixType = type;
    this.matrixItems = items;
    if(type === 'month') {
      this.selectedItems = this.addEditSchedulerForm.get('month')?.value;
    }
    if(type === 'day') {
      this.selectedItems = this.addEditSchedulerForm.get('day')?.value;
    }
    if(type === 'dweek') {
      this.selectedItems = this.addEditSchedulerForm.get('dweek')?.value;
    }
    if(type === 'hour') {
      this.selectedItems = this.addEditSchedulerForm.get('hour')?.value;
    }
    if(type === 'minute') {
      this.selectedItems = this.addEditSchedulerForm.get('minute')?.value;
    }
    if(this.selectedItems == '*') {
      this.selectedItems = items.map(item => (item.id).toString());
    }
    this.matrixModal.showModal();
  }

  setItems(type, value) {
    this.addEditSchedulerForm.get(type)?.setValue(value);
    this.matrixModal.hideModal();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
