import { FapBaseModel } from '../../base/fap-base-model';

export class SensorModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'device',
        'type',
        'data',
        'last_value',
        'icon',
        'name_t',
        'representation',
        'sensor_ref',
        'form'
    ];

    public static requiredFields: Array<string> = [
        'id'
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public name: string;
    public device: string;
    public type: string;
    public data: any;
    public lastValue : any
    public icon: any;
    public nameT: string;
    public representation: any;
    public sensorRef: any;
    public form?: any;
}
