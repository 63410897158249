<div class="scheduler_page" id="parent">
    <div class="scheduled_groups">
        <mat-accordion class="sgroups" multi openAll>
            <mat-expansion-panel expanded hideToggle class="group">
                <mat-expansion-panel-header class="hrow" #panelH (click)="panelH._toggle()">
                    <mat-panel-title class="title" (click)="panelH._toggle()">
                        <h2>{{ actuator?.name_t ? (actuator?.name_t | getTranslation : globalRegistry?.systemData?.translationsMap : langString) : 'No name' }}</h2>
                    </mat-panel-title>
                    <mat-panel-description class="info">
                        <span class="farm_name">{{ device?.id }}  {{device?.name_t ? (device.name_t | getTranslation : globalRegistry?.systemData?.translationsMap : langString) : device?.name}}</span>
                        <span class="lot_name"></span>
                        <span class="add_icon" style="display:none;">
                            <div class="rounded-circle v" (click)="getFormTypes(actuator)"></div>
                        </span>
                        <i class="fa fa-edit" (click)="getFormTypes(actuator)"></i>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="col-12 mt-3">
                    <ng-container *ngFor="let trigger of actuator?.triggers">
                        <ng-container *ngIf="getScheduler(trigger?.scheduler) as scheduler">
                          <div class="sched_row">
                            <fap-scheduler-row
                                [trigger]="trigger"
                                [scheduler]="scheduler"
                                [showHeader]="false"
                            ></fap-scheduler-row>
                          </div>
                        </ng-container>
                      </ng-container>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<fap-modal #schedulerTriggerModal [inputTitle]="'scheduler.command' | translate" [showFooter]="false" [inputIsMedium]="true">
    <fap-scheduler-trigger 
        [formTypeId]="sensor?.form"
        [sensorId]="sensor?.id"
    ></fap-scheduler-trigger>
</fap-modal>