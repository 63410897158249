import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { GlobalRegistryService } from "../../../../../core/global-registry/global-registry.service";
import { LotModel } from "../../../../../core/models/lot/lot.model";
import { PersonModel } from "../../../../../core/models/person/person.model";
import { TranslationModel } from "../../../../../core/models/type/translation-type.model";
import { CompanyService } from "../../../../../core/services/api/company/company.service";
import { NavService } from "../../../../../shared/services/nav.service";
import { NoteTypeModel } from "../../../../../core/models/type/note-type.model";
import { Router } from "@angular/router";
const d = new Date();

@Component({
  selector: "fap-notes-card",
  templateUrl: "./fap-notes-card.component.html",
  styleUrls: ["./fap-notes-card.component.scss"],
})
export class FapNotesCardComponent implements OnInit, OnDestroy {
  @Input()
  public note;
  public sameDate = false;
  public sameYear = true;
  public currentYear = d.getFullYear();
  public showYear = false;
  public commonYear = true;
  public lots: Array<LotModel>;
  @Input()
  public people: Array<PersonModel>;
  public person: PersonModel;
  public assignedTo: PersonModel;
  public translatedNames: Array<TranslationModel>;
  @Output()
  public deleteNote: EventEmitter<number> = new EventEmitter();
  @Input() public noteTypes: Array<NoteTypeModel> = [];

  public subscription: Subscription = new Subscription();

  public defaultImg: string;
  public langString: string;
  public mediaUrl = environment.mediaUrl

  constructor(public globalRegistry: GlobalRegistryService, public navService:NavService, public companyService: CompanyService, private router: Router) {
    this.lots = this.globalRegistry.systemData.lots;
    
    this.translatedNames = globalRegistry.systemData.translations;
  }

  ngOnInit(): void {
    this.defaultImg = 'assets/images/people_placeholder.svg';
    
    this.langString = localStorage.getItem('language');
      this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
        if(lang) {
          this.langString = lang;
        }
    }));
    
    if(this.note) {
    let s2:string = this.note.timestamp.toLocaleString('en-US');
    let s3:string
    let s4:string
    if(this.note.until) {
      let s1:string = this.note.until.toLocaleString('en-US');
      s1 = s1.substring(0, s1.length - 9);
      s3 = s1.substring(0, s1.length - 6);
      s4 = s2.substring(0, s1.length - 6);
      if(s1 == s2) {
        this.sameDate = true
      } else {
        this.sameDate = false;
      }
    }
    s2 = s2.substring(0, s2.length - 9);
    if(s3 == s4) {
      this.sameYear = true;
      const s5 = s3;
      console.log(s5, this.currentYear.toString())
      if(s5 == this.currentYear.toString()) {
        this.commonYear = true;
      } else {
        this.commonYear = false;
      }
    } else {
      this.sameYear = false;
    }
    }
  }

  getTranslation(translation) {
    const t =this.translatedNames.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
      return translation
    }
  }

  editNote(noteId: number) {
    this.router.navigate(['pages/notes/edit/' + noteId]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
