import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { ObjectModel } from '../../../core/services/api/objects/object.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { NavService } from '../../services/nav.service';
import { DataService } from '../../../core/services/websocket/data.service';
import { ForecastService } from '../../../core/services/api/forecast/forecast.service';
import { CompanyService } from '../../../core/services/api/company/company.service';
import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
import { Router } from '@angular/router';
import { MapService } from '../../layout/fap_main-map/service/map-service';
import { FapModalComponent } from '../../partials';
import { TypesService } from '../../../core/services/api/types/types.service';
import { ToastrService } from 'ngx-toastr';
import { AttributeService } from '../../../core/services/api/attribute/attribute.service';
import { ObjectEnum, UnitEnum } from './sensor-codes.enum';
import moment from 'moment';

@Component({
  selector: 'fap-pool-widget',
  templateUrl: './pool-widget.component.html',
  styleUrls: ['./pool-widget.component.scss']
})
export class PoolWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() activities: any[] = [];
  @Input() sensors: SensorModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() activityTypes: UnitTypeModel[] = [];
  @Input() index: number;
  @Input() triggerEditForm: any;
  @Input() editValue = true;
  @Output()
  public emitTriggerEditForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public emitFormUpdate: EventEmitter<any> = new EventEmitter<any>();
  public subscriptions: Array<Subscription> = [];
  public mediaUrl = environment.mediaUrl;
  public translatedNames: any = [];
  @Input() public langString: string;
  public toggleMain = false;
  public toggleSub = false;
  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() 
  public emitDelete: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('addEditPoolModal')
  public addEditPoolModal: FapModalComponent;
  @ViewChild('criticalDeleteModal')
  public criticalDeleteModal: FapModalComponent;
  public lastValues = [];
  public imageId = null;
  public imageData = null;
  public temperatureData = null;
  public lidData = null;
  public phData = null;
  public redoxData = null;
  public electrolysisData = null;
  public PoolightsData = null;
  public coords = [13.023853189945852, 80.21127657935895];
  public weatherData;
  public entityInfo: any
  public objectId: any;
  public isDynamic = false
  public selectedWidget;
  public entityRelation = '';
  public unitTypesMap: any;
  public phTimestamp;
  public redoxTimestamp;
  
  constructor(public dataService: DataService, public companyService: CompanyService, public cacheService: CacheResolverService,
    public forecastService: ForecastService, public navService: NavService, public globalRegistry: GlobalRegistryService, public router: Router, public mapService: MapService, public typesService: TypesService, private toastr: ToastrService, public attributeService: AttributeService) { }

  hideMain() {
    this.toggleMain = false;
}

toggleMenu() {
    this.toggleMain = !this.toggleMain;
}

ngOnInit(): void {
      this.translatedNames = this.globalRegistry.systemData.translations;
}

getTranslation(translation) {
  const t =this.translatedNames.filter(trans => {
    return trans.id === translation
  });
  if(t[0]) {
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
      return translation
    }
    } else {
      return translation
    }
}

ngOnChanges(changes: SimpleChanges): void {
  if(Object.prototype.hasOwnProperty.call(changes, 'widget') && this.widget) {
    this.drillData();
    if(this.index && this.widget.settings['form'] && this.widget.settings['form'].FT_POOL_COORDS.input && this.widget.settings['form'].FT_POOL_COORDS.input.coordinates) {
        const location = {
          lat: this.widget.settings['form'].FT_POOL_COORDS.input.coordinates[0],
          lng: this.widget.settings['form'].FT_POOL_COORDS.input.coordinates[1],
        }
        this.mapService.mapFocusPoint.lat = location.lat;
        this.mapService.mapFocusPoint.lng = location.lng;
        this.mapService.centerMapOnUserLocation();
      }
    if(this.widget.settings['form'] && this.widget.settings['form'].FT_POOL_COORDS.input && this.widget.settings['form'].FT_POOL_COORDS.input.coordinates) {
      this.coords = this.widget.settings['form'].FT_POOL_COORDS.input.coordinates;
      console.log(this.coords);
    }
  }
  if(Object.prototype.hasOwnProperty.call(changes, 'triggerEditForm') && this.triggerEditForm !== 0 && this.triggerEditForm === this.widget.id ) {
    console.log('method called!');
    this.getFormTypes();
  }
  if(changes.hasOwnProperty('unitTypes')) {
    this.unitTypesMap = new Map<number, any[]>();
    this.createObjectMap(this.unitTypes, this.unitTypesMap);
  }
}

public createObjectMap(arrayOfObjects: any[], objectMap: Map<number, any>): Map<number, any> {
  arrayOfObjects.forEach(obj => {
      objectMap.set(obj.id, obj);
      if(obj.children && obj.children.length) {
         this.createObjectMap(obj.children, objectMap);
      }
  });
  return objectMap;
}

public getFormTypes() {
  const url = this.typesService.getUrl('form/');
  this.cacheService.delete(url + 'uniqueid=POOL_CREATE');
  const params = { uniqueid: 'POOL_CREATE' };
  this.typesService.getFormTypes(params).subscribe((data) => {
      this.addEditPoolModal.showModal();
      this.entityInfo = data.body.results[0];
      this.objectId = this.widget.objectId;
      this.isDynamic = true;
      this.entityRelation = 'pool'
  });
}

resetValues() {
  this.entityInfo = null;
  this.objectId = null
  this.triggerEditForm = 0;
  this.isDynamic = false
  this.entityRelation = '';
  // this.addEditPoolModal.hideModal();
}

public drillData() {
  if(this.widget.settings) {
    console.log(this.widget.settings);
    if(this.widget.settings['form'] && this.widget.settings['form'].FT_POOL_IMAGES.input) {
      this.getCompanyImage(this.widget.settings['form'].FT_POOL_IMAGES.input[0])
    }
  setTimeout(()=> {
    const sensorIds = this.widget["settings"]["streams"].map((sensor) => sensor.id);
    this.subscriptions.push(this.dataService.getLastData(sensorIds.toString()).subscribe((data) => {
      this.lastValues = data
      const localDict = [...data];
      localDict.forEach(element => {
        this.sensors.forEach(sensor => {
          if(element.sensor === sensor.id) {
            Object.assign(element, {sensor_info: sensor})
            this.unitTypes.forEach(unit => {
              if(unit.id === element.sensor_info.unitType) {
                Object.assign(element, {unit: unit})
              }
            })
          }
        });
        this.widget["settings"]["streams"].forEach(s => {
          if(element.sensor === s.id) {
            Object.assign(element, {ref: s.ref})
          }
        });
        if(element.ref === this.getIdentification(ObjectEnum.Bin, UnitEnum.Lid)) {
          this.lidData = {element}
        } else if(element.ref === this.getIdentification(ObjectEnum.Pool, UnitEnum.Temperature)) {
          this.temperatureData = {element}
        } else if(element.ref === this.getIdentification(ObjectEnum.Pool, UnitEnum.Ph)) {
          this.phData = {element};
          const date = element.timestamp ? moment.utc(element.timestamp).local().fromNow() : null;
          this.phTimestamp = date
        } else if (element.ref === this.getIdentification(ObjectEnum.Pool, UnitEnum.Redox)) {
          this.redoxData = {element};
          const date = element.timestamp ? moment.utc(element.timestamp).local().fromNow() : null;
          this.redoxTimestamp = date
        } else if (element.ref === this.getIdentification(ObjectEnum.Pool, UnitEnum.Dose)) {
          this.electrolysisData = {element}
        } else if (element.ref === this.getIdentification(ObjectEnum.PoolLight, UnitEnum.Lid)) {
          this.PoolightsData = {element}
        } else {
          return
        }
      });
    }, (error) => {
      console.error(error.error.results.error);
    }))
    this.getForecasts();
  }, 1000)
}
}

getIdentification(objectId: number, unit: number): string {
  return objectId+'_'+unit;
}

goToDeviceView(element?) {
  if(element) {
    this.router.navigate(['pages/devices/view/'+element.sensor_info.device])
  }
  if(!element) {
    if(this.temperatureData) {
    this.router.navigate(['pages/devices/view/'+this.temperatureData.element.sensor_info.device])
    }
  }
}

getCompanyImage(id) {
  this.companyService.getCompanyImage(id).subscribe(data => {
    this.imageData = data.model;
    console.log(this.imageData)
  })
}

public getForecasts() {
  // if(this.coords) {
  console.log(this.coords);
  const param = {
    coords: this.coords.toString()
  }
  const url = this.forecastService.getUrl('');
  this.cacheService.delete(url + 'forecast/coords='+param.coords)
  this.forecastService.getForecast(param).subscribe(data => {
    this.weatherData = data.body.results[0];
    console.log(this.weatherData);
  })
// }
}

getObjectValues(obj: any): any[] {
  return Object.values(obj);
}

ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    })
}

triggerWorkFlow() {
  console.log('trigger workflow')
}

hideModal() {
  this.triggerEditForm = 0;
  this.emitTriggerEditForm.emit(false);
  this.addEditPoolModal.hideModal();
}

onUpdateAction(event) {
  if(event) {
    console.log("method called")
    this.emitFormUpdate.emit(true);
    this.addEditPoolModal.hideModal();
  }
}

validateDelete() {
  this.criticalDeleteModal.showModal();
}

getRandomNumber(maxValue: number): string {
  return Math.floor(Math.random() * (maxValue + 1)) + '%';
}

deletePool() {
  this.emitDelete.emit(this.widget);
  this.criticalDeleteModal.hideModal();
  this.toastr.success("Pool deleted successfully");
}

editForm() {
  let params = {
    unique_type: 'POOL_CREATE',
    object_id: this.widget.objectId
  }
  this.attributeService.getAttributeForm(params).subscribe(data => {
    console.log(data);
    this.getFormTypes()
  })
}

}
