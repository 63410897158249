<span *ngIf="isLoading" class="loader">
  <i></i>
</span>
<ng-container *ngFor="let note of notes">
  <div class="cards-grid">
    <fap-notes-card
      [note]="note"
      [noteTypes]="types"
      (deleteNote)="deleteNotes($event)"
      (getPerson)="getPerson($event)"
      [people]="persons"
    ></fap-notes-card>
  </div>
</ng-container>
