import { EnvironmentSettings } from "./environment-settings.interface";

export const environment: EnvironmentSettings = {
  production: true,
  isMockEnabled: false, // You have to switch this, when your real back-end is done
  baseBackendUrl: window["env"]["apiUrl"] + '/' || "default",
  overviewToken: window["env"]["apiOverviewToken"] || "default",
  mapAPIKey: window["env"]["mapAPIKey"] || "default",
  mediaUrl: window["env"]["apiUrl"] + '/' + 'media/' || "default",
  appName: window["env"]["appName"] || "default",
  appIcon: window["env"]["appIcon"] || window["env"]["apiUrl"] + '/' + 'media/settings/app_icon.png',
  secure: false,
  fap: "Lumbara",
  layoutConfig: {
    settings: {
      layout_type: "ltr",
      sidebar: {
        type: "default",
        body_type: "default",
      },
      sidebar_setting: "default-sidebar",
      sidebar_backround: "dark-sidebar",
      sidebar_background_image: "./assets/images/fap/background.svg",
      sidebar_logo: "./assets/images/fap/platform_logo.svg",
    },
    color: {
      layout_version: "light",
      color: "color-2",
      primary_color: "#4466f2",
      secondary_color: "#1ea6ec",
      accent_color: "#EBFF00",
      mix_layout: "default",
    },
    pallet: {
      sidebar_background: "", // Leave empty string for default styling
      header_background: "", // Leave empty string for default styling
      hover_color: "",
      active_color: "",
      search_color: "rgba(255, 255, 255, 0.6)",
      triangle: "assets/images/fap/triangle_green.svg",
    },
    router_animation: "fadeIn",
  },
  menuItems: [
    {
      path: "/pages/posts",
      title: "leftMenu.overview",
      icon: "assets/images/fap/Overview.svg",
      type: "link",
      key: "overview",
      badgeType: "primary",
    },
    {
      path: "/pages/home",
      title: "leftMenu.dashboard",
      icon: "",
      type: "link",
      key: "dashboard",
    },
    {
      path: "/pages/entities",
      title: "leftMenu.entities",
      icon: "",
      type: "link",
      key: "entities",
    },
    {
      path: "/pages/simulations",
      title: "leftMenu.simulations",
      icon: "",
      type: "link",
      key: "simulations",
    },
    {
      path: "/pages/scheduler",
      title: "leftMenu.scheduler",
      icon: "",
      type: "link",
      key: "scheduler",
    },
    // {
    //     path: '/pages/farm',
    //     title: 'leftMenu.farms',
    //     icon: 'assets/images/fap/Plantations.svg',
    //     type: 'link',
    //     permissions: ['COMPANY_ADMIN']
    // },
    {
      path: "/pages/lots",
      title: "leftMenu.lots",
      icon: "assets/images/fap/Plantations.svg",
      type: "link",
      key: "lots",
    },
    {
      path: "/pages/crops",
      title: "leftMenu.crops",
      icon: "",
      type: "link",
      key: "crops",
    },
    {
      path: "/pages/forms",
      title: "leftMenu.forms",
      icon: "",
      type: "link",
      key: "forms",
    },
    {
      path: "/pages/workflows",
      title: "leftMenu.workflows",
      icon: "",
      type: "link",
      key: "workflows",
    },
    {
      path: "/pages/pools",
      title: "leftMenu.pools",
      icon: "",
      type: "link",
      key: "pools",
    },
    {
      path: "/pages/fields",
      title: "leftMenu.fields",
      icon: "",
      type: "link",
      key: "fields",
  },
    // {
    //     path: '/pages/activity',
    //     title: 'widget.activities',
    //     icon: 'shopping-bag',
    //     type: 'link'
    // },
    {
      path: "/pages/people",
      title: "leftMenu.people",
      icon: "assets/images/fap/People.svg",
      type: "link",
      key: "people",
    },
    {
      path: "/pages/equipments",
      title: "leftMenu.equipments",
      icon: "assets/images/fap/Equipments.svg",
      type: "link",
      key: "equipments",
    },
    {
      path: "/pages/activities",
      title: "leftMenu.activities",
      icon: "",
      type: "link",
      key: "activities",
    },
    {
      path: "/pages/planning",
      title: "leftMenu.planning",
      icon: "",
      type: "link",
      key: "planning",
    },
    {
      path: "/pages/plan",
      title: "leftMenu.plan",
      icon: "",
      type: "link",
      key: "plan",
    },
    {
      path: "/pages/stock",
      title: "leftMenu.stock",
      icon: "",
      type: "link",
      key: "stock",
    },
    {
      path: "/pages/reports",
      title: "leftMenu.reports",
      icon: "",
      type: "link",
      key: "reports",
    },
    {
      path: "/pages/my-products",
      title: "leftMenu.myProducts",
      icon: "",
      type: "link",
      key: "my-products",
    },
    {
      path: "/pages/devices",
      title: "leftMenu.devices",
      icon: "",
      type: "link",
      key: "devices",
    },
    {
      path: "/pages/products_usage",
      title: "leftMenu.productsUsage",
      icon: "",
      type: "link",
      key: "products_usage",
    },
    {
      path: "/pages/diseases/weed",
      title: "leftMenu.weeds",
      icon: "",
      type: "link",
      key: "weed",
    },
    {
      path: "/pages/diseases/fungal",
      title: "leftMenu.fungal",
      icon: "",
      type: "link",
      key: "fungal",
    },
    {
      path: "/pages/diseases/viral",
      title: "leftMenu.viral",
      icon: "",
      type: "link",
      key: "viral",
    },
    {
      path: "/pages/diseases/insect",
      title: "leftMenu.insects",
      icon: "",
      type: "link",
      key: "insect",
    },
    // {
    //     path: '/pages/weeds',
    //     title: 'leftMenu.weeds',
    //     icon: 'feather',
    //     type: 'link'
    // },
    // {
    //     path: '/pages/insects',
    //     title: 'leftMenu.insects',
    //     icon: 'gitlab',
    //     type: 'link'
    // },
    // {
    //     path: '/pages/pathogens',
    //     title: 'leftMenu.pathogens',
    //     icon: 'alert-triangle',
    //     type: 'link'
    // },
    // {
    //     path: '/pages/units',
    //     title: 'leftMenu.units',
    //     icon: 'sliders',
    //     type: 'link'
    // },
    // {
    //     path: '/pages/party',
    //     title: 'leftMenu.party',
    //     icon: 'globe',
    //     type: 'link'
    // },
    {
      path: "/pages/notes",
      title: "leftMenu.notes",
      icon: "",
      type: "link",
      key: "notes",
    },
    {
      path: "/pages/alergotel_bio-particles",
      title: "leftMenu.bioParticles",
      icon: "",
      type: "link",
      key: "alergotel_bio-particles",
    },
    {
      path: "/pages/alergotel_recommendations",
      title: "leftMenu.recommendations",
      icon: "",
      type: "link",
      key: "alergotel_recommendations",
    },
    {
      path: "/pages/alergotel_alergic-profile",
      title: "leftMenu.alergicProfile",
      icon: "",
      type: "link",
      key: "alergotel_alergic-profile",
    },
    {
      path: "/pages/alergotel_reports",
      title: "leftMenu.alergotelReports",
      icon: "",
      type: "link",
      key: "alergotel_reports",
    },
    {
      path: "/pages/analytics",
      title: "leftMenu.analytics",
      icon: "",
      type: "link",
      key: "analytics",
    },
    // {
    //     path: '/pages/attributes',
    //     title: 'leftMenu.attributes',
    //     icon: 'share-2',
    //     type: 'link'
    // },
    // {
    //     path: '/pages/precision',
    //     title: 'leftMenu.precision',
    //     icon: 'archive',
    //     type: 'link'
    // },
  ],
  version: "4.18.45",
};
