<div class="notes-view d-flex d-md-flex bd-highlight" (click)="editNote(note?.id)" [ngClass]="{ 'note-overlay': !note?.active }" >
  <span *ngFor="let type of noteTypes">
    <div
        class="abs_child"
        [style]="{ background: type?.color }"
        *ngIf="type?.id == note.type"
    ></div>
</span>
  <div class="bd-highlight typebg">
    <span *ngFor="let type of noteTypes">
      <span
          class="note-type"
          [style]="{ background: type?.color }"
          *ngIf="type?.id == note.type"
      >
      <span class="vertical-label" *ngIf="!note.active">{{"notes.closed" | translate}}</span>
    </span>
  </span>
  </div>
  
  <div class="bd-highlight noteimg">
    <div class="card-image valign-wrapper user_im img_place_bg">
      <ng-container *ngFor="let person of globalRegistry?.systemData?.persons">
        <ng-container *ngIf="person?.id == note?.created_by">
      <img *ngIf="person?.picture"
        [src]="mediaUrl + person?.picture"
        [alt]="'creator'"
      />
      <img *ngIf="!person?.picture"
        src="../../../../../../assets/images/image_placeholder.png"
        [alt]="'creator'"
      />
    </ng-container>
    </ng-container>
    </div>
    
</div>
<div class="right_row">
  <div class="title">
    <h5>{{note?.name ? note?.name : 'No name'}}</h5>
  </div>
  <div class="sub_contents d-flex">
  <div class="p-2 bd-highlight w-50 sample info"> 
  <p class="m-0">
    <ng-container *ngFor="let type of noteTypes">
      <span *ngIf="type?.id == note?.type">{{type?.name}}</span>
    </ng-container>
  </p>
</div>
<div class="p-2 bd-highlight w-50 sample datetime" > 
  <div>
    <div>
      <span >{{note?.timestamp | date: "MMM dd yyyy HH:mm"}}</span>
      <span *ngIf="note?.until">
        <span style="display: block;" class="card-cell-time-color">{{(note?.until | date: "MMM dd yyyy HH:mm")}}</span></span> 
    </div>
  </div>
  </div>
  <div class="p-2 bd-highlight w-50 sample desc pc" style="display: -webkit-box; line-clamp: 2;
  -webkit-line-clamp: 2; padding-top: 0!important; padding-bottom: 0!important;
  -webkit-box-orient: vertical; text-align: left;
  overflow: hidden;"> 
    <span *ngIf="note?.info === '' || note?.info == null">No info</span>
    <span *ngIf="note?.info !== ''">{{note?.info}}</span>
    </div>
  </div>
    
  </div>
    <div class="final_row">
    <div style="width: 72px; min-height: 1px;" class="bd-highlight assigned_to"> 
      <ng-container *ngFor="let person of globalRegistry?.systemData?.persons">
        <ng-container *ngIf="note?.assigned_to">
    <span *ngIf="note?.assigned_to === person?.id">
      <div class="card-image valign-wrapper user_im img_place_bg" >
        <img 
          [src]="mediaUrl + person?.picture"
          [alt]="'creator'"
          *ngIf="person?.picture"
        />
        <img
          src="../../../../../../assets/images/image_placeholder.png"
          [alt]="'creator'"
          *ngIf="!person?.picture"
        />
      </div>
    </span>
  </ng-container>
  </ng-container>
</div>
      </div>
<div class="p-2 bd-highlight w-25 sample desc mobl" style="display: -webkit-box; line-clamp: 2;
-webkit-line-clamp: 2; padding-top: 0!important; padding-bottom: 0!important;
-webkit-box-orient: vertical; text-align: left;
overflow: hidden;"> 
    {{ note?.info ? note?.info : "-" }}
    </div>
</div>
