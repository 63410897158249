import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, of, Subscription } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { FieldInterface } from 'src/app/core/interfaces/field/field.interface';
import { LotModel } from 'src/app/core/models/lot/lot.model';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { MapPolygonInterface } from 'src/app/shared/layout/fap_main-map/data/map-polygon.interface';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { ActivityTypeModel } from '../../../../../core/models/activity/activity-type.model';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { PlanTypeModel } from '../../../../../core/models/company/plan.type.model';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { NavService } from '../../../../../shared/services/nav.service';
import { FapModalComponent } from '../../../../../shared/partials';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { EquipmentService } from '../../../../../core/services/api/equipment/equipment.service';

@Component({
    templateUrl: './activities-container.component.html',
    styleUrls: ['./activities-container.component.scss']
})
export class ActivitiesContainerComponent implements OnInit, OnDestroy {
    private subscriptions: Array<Subscription> = [];
    public farms: Array<FarmModel>;
    public filteredFarms: Array<FarmModel> = [];
    public currentUser;
    public activities: Array<any>;
    public filteredActivities: Array<any>;
    public activityTypes: Array<ActivityTypeModel>;
    public planTypes: Array<PlanTypeModel>;
    public nextToken: { offset: number; limit: number } = null;
    public limit: number = 50;
    public offset = 0;
    public getMore = true;
    public personIds = [];
    public lotPolygon: MapPolygonInterface;
    public lotFields: Array<FieldInterface> = [];
    public isSelectDialogOpen: boolean = false;
    public searchString: string ='';
    public count: number = 0;
    public pageNumber: number = 1;
    public isLoading: boolean = false;
    public workflowTypeId: number = null;
    public pageKey = 'activities';
    public langString: string;
    public subscription: Subscription = new Subscription();
    public activity = null;
    public isNewActivity: boolean = false;
    public equipments: Array<EquipmentModel> = [];
    public baselineActivityId = null;
    public baselineActivity: ActivityModel;
    @ViewChild('addEditBaselineActivityModal')
    public addEditBaselineActivityModal: FapModalComponent;
    @ViewChild('addEditActivityModal')
    public addEditActivityModal: FapModalComponent;
    public formTypes = [];
    @ViewChild('mys') mys;
    public selectedPlans: string = '';

    constructor(
        public farmService: FarmService,
        public activatedRoute: ActivatedRoute,
        public userService: UserService,
        public router: Router,
        public activityService: ActivityService,
        public mapService: MapService,
        public toastr: ToastrService,
        public translateService: TranslateService,
        public typeService: TypesService,
        public globalRegistry: GlobalRegistryService,
        public companyService: CompanyService,
        public cacheService: CacheResolverService,
        public attributeService: AttributeService,
        public widgetService: WidgetsService,
        private navService: NavService,
        private equipmentService: EquipmentService,
        private translate: TranslateService,
        private typesService: TypesService
    ) {
      this.isLoading = true;
        this.subscriptions.push(
            this.userService.getCurrentUser.subscribe((data) => {
                if (Object.keys(data).length != 0) {
                    this.currentUser = data;
                    console.log(data);
                    if(data.profile.data.run) {
                      let key = Object.keys(data.profile.data.run)
                      console.log(key);
                      if(key[0] === this.pageKey) {
                        this.workflowTypeId = data.profile.data.run[this.pageKey].workflow.type;
                        console.log(this.workflowTypeId);
                        if(this.workflowTypeId) {
                          // this.getWorkflow(this.workflowTypeId)
                        }
                      }
                    }
                    const path = this.router.routerState.snapshot.url;
                    this.userService.getMenus.subscribe((menus) => {
                        if (Object.keys(menus).length != 0) {
                            const pathExist = menus.some(
                                (menu) => menu.path === path
                            );
                            if (pathExist === false) {
                                this.router.navigate(['pages/posts']);
                            }
                        }
                    });
                }
            })
        );
        const localQueryParams = localStorage.getItem("queryParams")
      ? JSON.parse(localStorage.getItem("queryParams"))
      : {};
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: localQueryParams,
          });
          this.subscriptions.push(
            combineLatest([
              this.activatedRoute.queryParams,
              this.activityService.getActivities({ page: this.pageNumber, search: this.searchString, deep: 1 }).pipe(
                catchError(err => {
                  this.toastr.error("Can't load activities");
                  return of(null);
                })
              )
            ]).subscribe(([queryParams, activities]: [Params, ResponseModel<ActivityModel[]>]) => {
              this.farms = globalRegistry.systemData.farms;
              console.log(activities)
              const filteredFarmIds: number[] = Array.isArray(queryParams['farms'])
                ? queryParams['farms'].map((farmId: string) => Number(farmId))
                : [Number(queryParams['farms'])];
      
              this.filteredFarms = this.farms.filter(farm => filteredFarmIds.includes(farm.id));
      
              this.activities = activities.body.results;
              this.filteredActivities = this.activities;
              this.count = activities.body.count;
      
              this.nextToken = activities.body.next ? this.globalRegistry.getQueryStringParams(activities.body.next.split('?')[1]) : null;
      
              const urlWithLimit = this.activityService.getUrl('activities/') + 'page='+this.pageNumber+'&search='+this.searchString+'&deep='+true;
              this.cacheService.delete(urlWithLimit);
              if(activities) {
                  this.isLoading = false;
              }
              if (queryParams['filter'] !== undefined) {
                this.isLoading = true;
                const search = (queryParams['filter'] as string).toLowerCase();
                this.searchString = search
                this.activityService.getActivities({ search, deep: 1 }).subscribe(data => {
                  const urlWithSearch = this.activityService.getUrl('activities/') + 'search=' + search + '&deep=' + 1;
                  this.cacheService.delete(urlWithSearch);
      
                  this.nextToken = data.body.next ? this.globalRegistry.getQueryStringParams(data.body.next.split('?')[1]) : null;
                  this.getMore = !!this.nextToken;
                  this.filteredActivities = data.body.results;
                  this.count = data.body.count
                  // this.getPersons();
                  this.isLoading = false;
                });
              } else {
                // this.getPersons();
              }
            })
          );
          this.subscriptions.push(this.equipmentService.getEquipments().pipe(take(1)).subscribe((equipments: ResponseModel<EquipmentModel[]>): void => {
            this.equipments = equipments.model;
            console.log(this.equipments);
        }));
    }

    getWorkflow(id) {
      this.attributeService.postWorkflow({type: id}).subscribe(data => {
        console.log(data);
      })
    }

    public getFormTypes() {
      const url = this.typesService.getUrl('form/');
      this.cacheService.delete(url);
      this.typesService.getFormTypes().subscribe((data) => {
          this.formTypes = data.body.results;
          console.log(data.body.results);
      });
    }

    getPersons() {
      this.companyService.getPersons({limit: 1000}).subscribe(data => {
        this.globalRegistry.systemData.persons = data.body.results;
      })
      }

    public refreshPersons() {
      this.globalRegistry.reloadPersons();
    }

    paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) {
          result[key] = value;
        }
        console.log(result);
        return result;
      }
 

    ngOnInit(): void {
      this.langString = localStorage.getItem('language');
      this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
        if(lang) {
            this.langString = lang;
        }
    }));
        this.typeService.getActivityTypes().subscribe((types:ResponseModel<ActivityTypeModel[]>) => {
            this.activityTypes = types.body.results;
        })
        this.typeService.getPlanTypes().subscribe((types:ResponseModel<PlanTypeModel[]>) => {
          this.planTypes = types.model;
        })
        this.widgetService.setMapResize(false);
        this.widgetService.setSize(7);
        this.mapService.showMap();
        this.mapService.isEditMode = false;
        this.mapService.mapPolygons = [];
        this.showAllFieldsOnMap();
        this.getFormTypes();
        this.getPersons();
        this.subscription.add(
          this.activityService.getNewActivities.subscribe(res => {
            if(res) {
              this.getActivities();
            }
          })
        )
    }

    public showAllFieldsOnMap(): void {  
      this.globalRegistry.systemData.lots.forEach((lot: LotModel): void => {
          this.mapService.mapPolygons.push({
              points: MapHelper.convertToAGMPolygon(lot.coords.coordinates[0]),
              strokeColor: '#33de55',
              fillColor: '#248a38',
              data: {field: true},
              isEditable: false
          });
      });
      this.mapService.centerMapOnPolygons();
  }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe()
        );
        this.widgetService.setMapResize(true);
        this.widgetService.setSize(6);
    }

    private initMap(): void {
        this.mapService.showMap();
        this.mapService.isEditMode = false;
        this.mapService.resetMap();
        this.filteredFarms.forEach((farm: FarmModel): void => {
            this.mapService.mapPolygons.push({
                points: MapHelper.convertToAGMPolygon(
                    farm.coords.coordinates[0]
                ),
                strokeColor: '#a31f1f',
                fillColor: '#de3333',
            });
        });

        this.mapService.centerMapOnPolygons();
    }

    public centerMapOnLot(lot: LotModel): void {
      const lotPolygon: MapPolygonInterface = this.mapService.mapPolygons
          .find((poly: MapPolygonInterface): boolean => poly.data['lot'] === lot.id);
      this.mapService.centerMapOnPolygon(lotPolygon);
  }

  filterActivitiesByPlan(event) {
    this.selectedPlans = event.value.toString();
    let params = { search: this.searchString, plan: this.selectedPlans, deep: 1 }
    this.activityService.getActivities(params).subscribe(data => {
      console.log(data);
      this.filteredActivities = data.body.results;
      this.mys.close();
    })
  }

  public handlePagination(pageNumber: number) {
    this.pageNumber = pageNumber;
    console.log(pageNumber);
    console.log(this.limit);
    console.log(pageNumber * this.limit);
    this.offset = pageNumber * this.limit - this.limit;
    this.getActivities();
  }

  public getActivities(): void {
    this.isLoading = true;
    const subscription = this.activityService.getActivities({ page: this.pageNumber, search: this.searchString, plan: this.selectedPlans, deep: 1 }).subscribe(
      (activities: ResponseModel<ActivityModel[]>) => {
        this.filteredActivities = [];
        this.filteredActivities = activities.body.results;
        console.log(activities);
        this.count = activities.body.count;
        this.nextToken = activities.body.next ? this.globalRegistry.getQueryStringParams(activities.body.next.split('?')[1]) : null;
        const urlWithLimit = this.activityService.getUrl('activities/') + 'page=' + this.pageNumber + '&search=' + this.searchString + '&plan=' + this.selectedPlans + '&deep=' + 1;
        this.cacheService.delete(urlWithLimit);
        // if(activities.body.count) {
          this.isLoading = false;
        // }
      },
      (error) => {
        console.error('Error fetching activities:', error);
        this.toastr.error(error.error.results.error);
      }
    );

    this.subscriptions.push(subscription);
  }

  createNewActivity() {
    this.activity = null;
    this.activityService.createActivity({}).subscribe(data => {
      this.activity = data.model;
      this.isNewActivity = true;
      this.addEditActivityModal.showModal();
    })
  }

  public deleteActivity(id): void {
    console.log(id);
      this.activityService.deleteActivity(id).subscribe(data => {
        this.toastr.success(this.translate.instant("activity.activityDeletedSuccessfully"));
        this.activityService.setActivityList(true);
        this.addEditActivityModal.hideModal();
      }, (err) => {
        this.toastr.error(err.error.results.error)
      })
  }

  public submitActivity(activity: ActivityModel): void {
    console.log(activity);
    this.activityService.createActivity(activity).subscribe(data => {
        if(data) {
            this.toastr.success(
                this.translate.instant("activity.activityCreatedSuccessfully")
              );
              this.activityService.setActivityList(true);
              this.globalRegistry.reloadPlans();
        }
    }, (err) => {
      this.toastr.error(err.error.results.error)
    })
  }

  public updateActivity(activity: ActivityModel): void {
      this.activityService.updateActivity(activity.id, activity).subscribe(data => {
        if(data) {
            this.toastr.success(
                this.translate.instant("activity.activityUpdatedSuccessfully")
              );
              this.activityService.setActivityList(true);
              this.globalRegistry.reloadPlans();
        }
      }, (err) => {
        this.toastr.error(err.error.results.error)
      })
  }

  setBaselineTrigger(activity) {
    console.log(activity)
      this.activity = activity;
      this.isNewActivity = false;
      if(activity.baseline) {
          console.log(activity.baseline)
      } else {
          this.createBaselineActivity(activity)
      }
    }

    public createBaselineActivity(activity) {
      const activityData = {};
      this.activityService.createBaselineActivity(activityData, activity.id).subscribe(data => {
          if(data) {
              console.log(data.model);
              this.baselineActivity = data.model;
              this.isNewActivity = true;
              this.activity.baseline = data.model.id
          }
      })
    }

    assignBaselineActivity(activityId) {
      this.baselineActivityId = activityId;
      this.addEditBaselineActivityModal.showModal();
    }

    public deleteBaselineActivity(id): void {
      console.log(id);
        this.activityService.deleteActivity(id).subscribe(data => {
          this.toastr.success(this.translate.instant("activity.baselineActivityDeletedSuccessfully"));
          this.addEditBaselineActivityModal.hideModal();
          this.activity.baseline = null;
        }, (err) => {
          this.toastr.error(err.error.results.error)
        })
    }

    public submitBaselineActivity(activity: ActivityModel): void {
      console.log(activity);
      this.activityService.createActivity(activity).subscribe(data => {
          if(data) {
              this.toastr.success(
                  this.translate.instant("activity.baselineActivityCreatedSuccessfully")
                );
                this.activity.baseline = data.model.id;
          }
      }, (err) => {
          this.toastr.error(err.error.results.error)
        })
    }

    public updateBaselineActivity(activity: ActivityModel): void {
        this.activityService.updateActivity(activity.id, activity).subscribe(data => {
          if(data) {
              this.toastr.success(
                  this.translate.instant("activity.baselineActivityUpdatedSuccessfully")
                );
                this.activity.baseline = data.model.id;
          }
        }, (err) => {
          this.toastr.error(err.error.results.error)
        })
    }

}
