import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription, using } from 'rxjs';
import { GlobalRegistryService } from 'src/app/core/global-registry/global-registry.service';
import { FarmModel } from 'src/app/core/models/farm/farm.model';
import { LotModel } from 'src/app/core/models/lot/lot.model';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { DeviceModel, ObjectModel, ObjectService } from 'src/app/core/services/api/objects/object.service';
import { StockService } from 'src/app/core/services/api/stock/stock.service';
import { UnitTypeModel, UnitTypeService } from 'src/app/core/services/api/unit-type/unit-type.service'
import { MapService } from 'src/app/shared/layout/fap_main-map/service/map-service';
import { FapModalComponent } from 'src/app/shared/partials';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { environment } from '../../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';
import { NavService } from '../../../../../shared/services/nav.service';
import { ScrollService } from '../../../../../shared/services/scroll.service';
import { filter } from 'rxjs/operators';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { UserModel } from '../../../../../core/models/user/user.model';
import { UserInterface } from '../../../../../core/services/api/user/data/user.interface';
import { MapsAPILoader } from '@agm/core';
import { EntityService } from '../../../../../core/services/api/entity/entity.service';
import { AuthService } from '../../../../../core/services/api/auth/auth.service';
export interface dataMapInterface {
  sensor: string; object_id: number; unit_type: number; value: number;
}

export interface tableInterface {
  product: any; 
  id: string;
  lastTransmit: any[];
}
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  content: string;
  color?: string;
  iconUrl?: any;
}

interface coords {
  lat: number;
  lng: number;
}

@Component({
  selector: 'devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.scss']
})
export class DevicesListComponent implements OnInit, OnDestroy, AfterViewChecked, OnChanges {


  @Input()
  get devices(): Array<DeviceModel> {
    return this._devices;
  }

  @Input() public showHeader: boolean = true;
  @Input() public triggerSensorGroupTypes: boolean = false;

  set devices(value) {
    this._devices = value;
    // do something
  }

  private _devices: DeviceModel[];
  public dynamicTable: Array<any> = [];
  public tableValues = [];
  public dataMap: Array<any> = [];
  public i = 3;
  private subscriptions: Array<Subscription> = [];
  public filterFarmIds: Array<number> = [];
  public filterLotIds: Array<number> = [];

  public filteredFarms: Array<FarmModel> = [];
  public filteredLots: Array<LotModel> = [];

  public objects: Array<ObjectModel> = [];
  public unitTypes: Array<UnitTypeModel> = [];

  public deviceForm: UntypedFormGroup;

  public tableHeaderLabels = [{index: 1, name: 'type <span>location</span>'}, {index: 2, name: 'last transmit'}];
  public lotNames = [];
  public resourceEntries = [];
  public entry: any = null;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  public max: number;
  @Input() public profiles = [];
  public mediaUrl = environment.mediaUrl;
  public limit = 50;
  public pageNumber: number = 1;
  public offset = 0;
  @Input() public deviceData = [];
  public isLoading = false;
  public searchString = '';
  @ViewChild('configModal') configModal: FapModalComponent;
  @ViewChild('deviceModal') deviceModal: FapModalComponent;
  @Output()
  public addNewDevice: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public currentView: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public setTriggerSensorGroupTypes: EventEmitter<any> = new EventEmitter<any>();
  public nextToken: { group: string, limit: number; offset: number; search: string; } = null;
  public deviceNextToken: { limit: number; offset: number; search: string; lot: string, group: string } = null;
  public getMore = true
  public getMoreData = true
  public mapIconUrl: any;
  public searchLots = [];
  @Input() public widgetLot = '';
  public sensorGroup = [];
  public itemList = [];
  public virtualItemList = [];
  public sensorForm: UntypedFormGroup;
  public sensorGroupTypeForm: UntypedFormGroup;
  sensors: SensorModel[] = [];
  public translatedNames:any = [];
  public langString: string;
  @Input() public deviceColumns = [];
  @Input() public allDeviceColumns = [];
  @Input() public sensorGroupTypes = [];
  draggedItemIndex: number;
  private scrollPosition = 0;
  public divHeight:number;
  @ViewChild('deviceContainer') deviceContainer: ElementRef;
  private isScrolled = false;
  private previousQueryParams: any = {};
  filteredColumns: any[] = [];
  translation = null;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('addEditTranslationsModal')
  public addEditTranslationsModal: FapModalComponent;
  public sensorGroupType: any;
  public addEditSensorGroupTypeModalButtonPrimaryInterface: FapModalButtonInterface;
  public addEditSensorGroupTypeModalButtonSecondaryInterface: FapModalButtonInterface;
  @Input() public sensorGroupTypeId: any = null;
  @ViewChild('addEditSensorGroupTypeModal')
  public addEditSensorGroupTypeModal: FapModalComponent;
  @ViewChild('mys') mys;
  public user: UserModel;
  public label:string;
  public closeCombo: boolean = true;
  public isSelectDialogOpen: boolean = false;
  columnWidths: number[] = [];
  @Input() bgColor: string = '';
  @ViewChild('mapModal')
  public mapModal: FapModalComponent;
  zoom = 8;
  public count: number = 0;
  markers:Array<marker> = [];
  public searchControl: UntypedFormControl;
  public mapSearchToggle: boolean;
  public mapTypeControl: boolean;
  @ViewChild('search', {static: true}) public searchElementRef: ElementRef;
  @Output()
  public coords: EventEmitter<coords> = new EventEmitter();
  defaultCenter = {lat: 51.673858, lng: 7.815982};
  currentCenter = Object.assign({}, this.defaultCenter);
  public infoWindowDetails = '';
  @Output()
  public refreshSensorGroupTypes: EventEmitter<any> = new EventEmitter<any>();
  public entityProfiles:any[] =[];

  constructor(public mapService: MapService, public ObjectService: ObjectService, public unitTypeService: UnitTypeService, public activatedRoute: ActivatedRoute, public globalRegistry: GlobalRegistryService, public stockService: StockService, public formBuilder: UntypedFormBuilder, public toastrService: ToastrService, public translateService: TranslateService, public objectService: ObjectService, public cacheService: CacheResolverService, public router: Router, public widgetsService: WidgetsService, private sanitizer: DomSanitizer, public sensorService: SensorService, public navService: NavService, public fb: UntypedFormBuilder, private scrollService: ScrollService, public typeService: TypesService, public toastr: ToastrService, private userService: UserService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private cdr: ChangeDetectorRef, private entityService: EntityService, private authService: AuthService) { 
    const localQueryParams = localStorage.getItem("queryParams")
      ? JSON.parse(localStorage.getItem("queryParams"))
      : {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: localQueryParams,
    });
    this.columnWidths = this.deviceColumns.map(() => null);
  // this.activatedRoute.queryParams.subscribe(queryParams => {
  //   if (Object.keys(queryParams).length === 0) {
  //     this.searchString = '';
  //     this.getDeviceData();
  //   }
  // })

  this.initSensorGroupTypeForm();

  this.router.events
  .pipe(filter(event => event instanceof NavigationEnd))
  .subscribe((event: NavigationEnd) => {
    const currentQueryParams = this.activatedRoute.snapshot.queryParams;
    console.log(event)
    if (this.previousQueryParams['filter'] !== undefined && Object.keys(currentQueryParams).length === 0) {
      this.searchString = '';
      // localStorage.setItem('deviceSearchString', this.searchString)
    }
    this.previousQueryParams = { ...currentQueryParams };
  });
  this.getScrollPosition();
  }

  navigateToDeviceDashboard(deviceId: number) {
    this.router.navigate(['pages/devices/view/' + deviceId])
  }

  editDevice(deviceId: number) {
    if(this.showHeader) {this.router.navigate(['pages/devices/edit/' + deviceId])}
  }

  initSensorGroupTypeForm() {
    this.sensorGroupTypeForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      name_t: new UntypedFormControl(''),
      color: new UntypedFormControl('#ff0000'),
    });
  }

  getScrollPosition() {
    this.scrollPosition = this.scrollService.getScrollPosition('device');
      setTimeout(() => {
        if(this.deviceData.length) {
          if (this.deviceContainer) {
            this.divHeight = this.deviceContainer.nativeElement.clientHeight;
            const contentHeight = this.deviceContainer.nativeElement.scrollHeight;
            if(this.scrollPosition > contentHeight) {
              this.scrolledDownDeviceData();
            }
            this.deviceContainer.nativeElement.scrollTop = this.scrollPosition;
          }
        }
      }, 500);
  }

  public handlePagination(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.offset = pageNumber * this.limit - this.limit;
    this.getDeviceData(this.sensorGroupTypeId, this.searchLots);
  }

  ngOnInit(): void {
    this.authService.getConfig().subscribe(data => {
      this.sensorGroupTypeId = data.body.results.entities_view_profile.selected_column ? data.body.results.entities_view_profile.selected_column : '';
    });
    console.log(this.sensorGroupTypeId);
    if(this.showHeader) {
      this.mapService.showMap();
      this.mapService.resetMap();
    }
    this.initDeviceForm();
    this.getProfiles();
    this.getAllDeviceDataColumns();
    this.getDeviceDataColumns(this.sensorGroupTypeId);
    this.getEntityProfiles();
    this.sensorForm = this.fb.group({
      sensorIds: [[], Validators.required],
      sensorGroup: [this.sensorGroupTypeId, Validators.required]
    });
    this.initAddsensorGroupTypeModalButtons();
    if(this.showHeader) {
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(data => {
      this.searchLots = data?.lots ?? [];
      if (data["filter"] !== undefined) {
        const search = (data["filter"] as string).toLowerCase();
        this.searchString = search;
      // localStorage.setItem('deviceSearchString', this.searchString)
      }
      setTimeout(() => {
        if(this.searchLots.length) {
          this.getDeviceData(this.sensorGroupTypeId, this.searchLots.toLocaleString())
        } else {
          this.getDeviceData(this.sensorGroupTypeId, '');
        }
      }, 500)
    }))} else {
      this.getDeviceData(this.sensorGroupTypeId, this.widgetLot)
    }
    this.langString = localStorage.getItem('language');
      this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
      if(lang) {
        this.langString = lang;
      }
    }));
    this.translatedNames = this.globalRegistry.systemData.translations;
    this.searchControl = new UntypedFormControl();

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.currentCenter.lat = place.geometry.location.lat();
          this.currentCenter.lng = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  get f() {
    return this.deviceForm.controls;
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.scrolledDown();
    }
}

onDivScroll(event: any) {
  const divElement = event.target as HTMLElement;
    const scrollTop = divElement.scrollTop;
    this.scrollPosition = scrollTop;
    this.scrollService.setScrollPosition(scrollTop, 'device')
  if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    this.scrolledDownDeviceData();
  }
}

scrolledDown() {
  const url = this.stockService.getUrl('stock_resources/');
  console.log('scrolled');
  if(this.getMore) {
  this.nextToken &&
    this.subscriptions.push(this.stockService.getDevicesResourceEntries(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'group=DEVICES&limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
      if(this.resourceEntries) {
        this.resourceEntries = [...this.resourceEntries, ...data.body.results];
      } else {
        this.resourceEntries = data.body.results;
      }
      if(data.body.next == null) {
        this.getMore = false;
        return
      } else {
        const url = data.body.next.split('?')
        const urlParams = new URLSearchParams(url[1]);
        const entries = urlParams.entries();
        const params = this.paramsToObject(entries);
        if(this.nextToken.offset != params['offset']) {
        this.nextToken = {group: 'DEVICES', limit: params['limit'], offset: params['offset'], search: params['search']};
        } else {
          return
        }
      }
    }));
  } else {
    return
  }
}

scrolledDownDeviceData() {
  const url = this.objectService.getUrl('adv_data/');
  console.log('scrolled');
  if(this.getMoreData) {
  this.deviceNextToken &&
    this.subscriptions.push(this.objectService.getAdvDeviceData(this.deviceNextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.deviceNextToken.limit+'&offset='+this.deviceNextToken.offset+'&search='+this.deviceNextToken.search+'&lot='+this.deviceNextToken.lot);
      if(this.deviceData) {
        const uniqueIds = new Set(this.deviceData.map(item => item.id));
        const newData = data.body.results.filter(item => !uniqueIds.has(item.id));
        this.deviceData = [...this.deviceData, ...newData];
        this.getScrollPosition();
      } else {
        this.deviceData = data.body.results;
        this.getScrollPosition();
      }
      if(data.body.next == null) {
        this.getMoreData = false;
        return
      } else {
        const url = data.body.next.split('?')
        const urlParams = new URLSearchParams(url[1]);
        const entries = urlParams.entries();
        const params = this.paramsToObject(entries);
        if(this.deviceNextToken.offset != params['offset']) {
        this.deviceNextToken = {limit: params['limit'], offset: params['offset'], search: params['search'] ? params['search'] : '', lot: params['lot'] ? params['lot'] : '', group: params['group']};
        } else {
          return
        }
      }
    }));
  } else {
    return
  }
}

paramsToObject(entries) {
  const result = {}
  for(const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

  public getResourceEntries() { 
    const url = this.stockService.getUrl('stock_resources/')
    this.cacheService.delete(url+'group=DEVICES');
    const param = {group:'DEVICES'}
    this.stockService.getDevicesResourceEntries(param).subscribe(data => {
      this.resourceEntries = data.body.results;
      if(data.body.results) {
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
            const search = {search : ''}
            Object.assign(this.nextToken, search)
      if(this.nextToken) this.getMore = true;
      }
    })
  }

  public setEntry(entry) {
    const entries = this.resourceEntries.filter(e => {
      return e.id == entry.value;
    });
    this.entry = entries[0];
    if(entry.value != null) {
      this.max = this.entry.quantity;
      this.deviceForm.controls['quantity'].setValue(this.entry.remains_quantity);
    }
  }

  getTranslation(translation) {
    const t =this.translatedNames.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
    }
  }

  onOptionSelected(event) {
      const ref = { sensor_ref: event.value };
  
      if (!this.isDuplicateItem(ref, this.itemList)) {
        this.itemList.push(ref);
      }
  
      const selectedColumn = this.allDeviceColumns.find(column => column?.sensor_ref?.id === ref.sensor_ref);
  
      if (selectedColumn && !this.isDuplicateColumn(selectedColumn, this.deviceColumns)) {
        this.deviceColumns.push(selectedColumn);
      }
  }
  
  isDuplicateItem(newItem, itemList) {
    return itemList.some(existingItem =>
      existingItem.sensor_ref === newItem.sensor_ref
    );
  }
  
  isDuplicateColumn(newColumn, deviceColumns) {
    return deviceColumns.some(existingColumn =>
      existingColumn?.sensor_ref?.id === newColumn?.sensor_ref?.id
    );
  }  

addColumns() {
  console.log(this.sensorForm.value);
  this.sensorGroupTypeId = this.sensorForm.get('sensorGroup').value;
  this.objectService.postColumns(this.itemList, this.sensorGroupTypeId ? this.sensorGroupTypeId : '').subscribe(data => {
    if(data) {
      this.toastrService.success('Header updated');
      console.log(data);
      this.configModal.hideModal();
      this.deviceColumns = data.body.results;
      localStorage.setItem('currentSensorGroup', this.sensorGroupTypeId);
      this.getDeviceData(this.sensorGroupTypeId);
      this.currentView.emit(this.sensorGroupTypeId);
    }
  })
}

showConfigModal() {
  this.configModal.showModal();
}

  showModal() {
    this.deviceModal.showModal();
}

  hideModal() {
    const elem = document.getElementById('device_modal');
    const parent = document.getElementById('parent');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
    this.router.navigate(['pages/devices'])
    this.deviceForm.reset();
    this.deviceForm.clearValidators();
    this.initDeviceForm();
    this.entry = null;
  }

  public addDevice() {
    if(this.deviceForm.invalid) {
      this.deviceForm.markAsTouched();
      this.toastrService.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
      return
    }
    this.toastrService.success('New device created successfully');
    this.addNewDevice.emit(this.deviceForm.value);
    this.hideModal();
  }

  updateItems(lots) {
    this.deviceForm.get('lots').setValue(lots);
  }

  public initDeviceForm() {
    const formBody = {
      resource_entry : ['', Validators.required],
      quantity: [0, Validators.required],
      lots: [[], Validators.required]
    };
    this.deviceForm = this.formBuilder.group(formBody);
  }

  public removeItem(sensor_ref_id: number): void {
    // const sensorIdsArray = this.sensorForm.get('sensorIds').value;
    // const index = sensorIdsArray.indexOf(sensor_ref_id);
  
    // if (index !== -1) {
    //   this.sensorForm.get('sensorIds').setValue(sensorIdsArray.filter(id => id !== sensor_ref_id));
    // }
  
    this.itemList = this.itemList.filter(item => item.sensor_ref !== sensor_ref_id);
  
    this.deviceColumns = this.deviceColumns.filter(column => column?.sensor_ref?.id !== sensor_ref_id);
  }
  
  
  
  toDateTime(secs) {
    return moment.unix(secs).utc().local().fromNow();
}

  mergeArrays(arrays) {
    return [...new Set([].concat(...arrays))];
  }

  getUnitTypeText(unitType) {
    const unitTypeObj = this.unitTypes.find(u => u.id === unitType);
    return unitTypeObj ? unitTypeObj.unit : '';
  }
 
  
  getCellStyles(sensor) {
    if(sensor) {
      let val = null;
      let valuePresent = false;
      val = sensor['representation']
      valuePresent = sensor['value_float'] || sensor['value_string']
      if(val && val.table && val.table.profile) {
      return this.getProfile(val.table.profile, valuePresent)
      }
    }
  }

  getProfile(profile, valuePresent) {
    const matchedProfile = this.profiles.find(obj => obj.uniqueid === profile);
      if(matchedProfile) {
        let img = ''
        if(matchedProfile.image) {
          img = this.mediaUrl + matchedProfile.image.file;
        }
        return {
          'font-weight': matchedProfile.bold ? 'bold' : 'normal',
          'font-style': matchedProfile.italic ? 'italic' : 'normal',
          'font-size': matchedProfile.text_size,
          'color': matchedProfile.text_color,
          'background-color': matchedProfile.back_color,
          'background-image': '',
          'background-position': valuePresent ? '5px center' : 'center',
          'img': img,
          'translate_x': matchedProfile.translate_x,
          'translate_y': matchedProfile.translate_y,
          'show_icon': matchedProfile.show_icon,
          'show_text': matchedProfile.show_text,
          'show_value': matchedProfile.show_value
        };
      }
  }

  getExtraFieldsById(sensor, unit, device) {
    let val = '';
    let text = '';
  
    text = sensor?.representation?.table?.text || null;
  
    if (sensor?.value_coord) {
      this.displayCoord(sensor, device);
      if (sensor.representation && sensor.representation.table) {
        const profile = this.getProfile(sensor.representation.table.profile, sensor.value_coord);
        const img = profile.img;
        val = `<span class="${img ? '' : 'btn'}"><img src="${img || '../../../../assets/icons/marker-white.png'}" alt="marker"></span>`;
        return val;
    } else {
        val = `<span class="btn custom_btn_img"><img src="../../../../assets/icons/marker-white.png" alt="marker"></span>`;
        return val;
    }
    }
  
    if (sensor?.value_float || sensor?.value_string) {
      val = sensor?.value_float ? sensor?.value_float.toFixed(this.globalRegistry.systemData.company.digits) : '';
      val += sensor?.value_string?.trim() ? ` <span class="unit">${unit}</span>` : '';
  
      if (sensor?.representation) {
        const profile = this.getProfile(sensor?.representation?.table?.profile, sensor?.value_float || sensor?.value_string);
  
        const img = profile?.img;
        let content = '<div class="has_image_value">';
  
        if (profile?.show_icon && img) {
          content += `<div class="img_div"><img src="${img}" alt="marker"></div>`;
        }
  
        if (profile?.show_text && profile?.show_value) {
          content += `<div class="img_value">${val ? `${text}<span class="small_text">${val}</span><span class="unit">${unit}</span></div>` : ''}`;
        } else if (profile?.show_value) {
          content += `<div class="img_value">${val || '-'}<span class="unit">${unit}</span></div>`;
        } else if (profile?.show_text) {
          content += `<div class="img_value">${text || '-'}</div>`;
        }
  
        return content + '</div>' || '-';
      } else {
        return `<div class="has_image_value">${val}<span class="unit">${unit}</span></div>` || '-';
      }
    }
  
    return ''; // Return an empty string if no conditions are met
  }
  

selectAllItems() {
  this.deviceColumns = this.allDeviceColumns;
  this.deviceColumns.forEach(column => {
    this.itemList.push({sensor_ref: column.sensor_ref.id});
  });
}

removeAllItems() {
  this.deviceColumns = [];
  this.itemList = [];
  // this.sensorForm.reset();
}

  handleButtonClick(id: string, sensor) {
    if (sensor && sensor.value_coord) {
      this.mapService.currentLocation.lat = sensor.value_coord.coordinates[1];
      this.mapService.currentLocation.lng = sensor.value_coord.coordinates[0];
      if(this.showHeader) {
        this.mapService.mainMapIsOpened = true;
        this.mapService.mapZoom.subscribe(zoom => {
          if(zoom < 18) {
            this.mapService.mapZoom.next(20)
          }
        })
        this.mapService.centerMapOnUserLocation(false);
      } else {
        this.markers = [];
        this.mapIconUrl = '';
        let iconUrl
        if (sensor.value_coord) {
          if (sensor.representation && sensor.representation.table) {
            const profile = this.getProfile(sensor.representation.table.profile, sensor.value_coord);
            const img = profile.img ? {
              url: profile.img,
              scaledSize: { width: 30, height: 30 }
            } : '';
            this.mapIconUrl = img;
            iconUrl = img
          }
        }
        console.log(sensor)
        console.log(iconUrl)
        let marker: marker = {
          lat: sensor.value_coord.coordinates[1],
          lng: sensor.value_coord.coordinates[0],
          draggable: false,
          content: id,
          iconUrl
        }
        this.currentCenter.lat = marker.lat;
        this.currentCenter.lng = marker.lng;
        this.markers.push(marker);
        this.mapModal.showModal();
      }
    }
  }

  displayAllCoords() {
    const uniqueMarkers = new Set<string>();
  
    this.deviceData.forEach(device => {
      if (device.coords && device.coords.last_value) {
        const markerKey = JSON.stringify(device); 
  
        if (!uniqueMarkers.has(markerKey)) {
          uniqueMarkers.add(markerKey);
  
          const infoWindowDetails = [];
          if (device.coords.last_value.representation) {
            const profile = this.getProfile(device.coords.last_value.representation.map.profile, device.coords.last_value.value_coord);
            let iconUrl;
            let label;
            const infoWindowDetails = [];
            if (profile && profile.show_icon) {
              iconUrl = {
                url: profile['img'],
                scaledSize: { width: 30, height: 30 }
              };
            }
            if (device.coords.last_value.representation.map.text && profile && profile.show_text) {
              label = { className: 'custom-white-marker-label', 'text': device.coords.last_value.representation.map.text, 'color': profile.color, 'font-size': '14px', 'background-color': 'red' };
            }
           
              infoWindowDetails.push({ img: null, text: device.coords.last_value.value_float ? device.coords.last_value.value_float.toFixed(2) : device.id, link: 'devices/view/'+device.id});
           
            if (device.coords && device.coords.last_value && profile && device.coords.last_value.value_coord) {
              const markerInterface = {
                lat: device.coords.last_value.value_coord.coordinates[1] + profile.translate_y,
                lng: device.coords.last_value.value_coord.coordinates[0] + profile.translate_x,
                // zoom: device.coords.last_value.value_coord.coordinates[2],
                label: label,
                iconUrl: iconUrl ? iconUrl : 'https://via.placeholder.com/15x15.png/000000?text=+',
                infoWindowDetails: infoWindowDetails
              };
              this.mapService.mapMarkers.push(markerInterface);
            }
          } else {
            infoWindowDetails.push({ img: null, text: device.id });
            const markerInterface = {
              lat: device.coords.last_value.value_coord.coordinates[1],
              lng: device.coords.last_value.value_coord.coordinates[0],
              // zoom: device.coords.last_value.value_coord.coordinates[2],
              infoWindowDetails: infoWindowDetails ? infoWindowDetails : null,
              mapIconUrl: ''
            };
            this.mapService.mapMarkers.push(markerInterface);
          }
        }
      }
    });
  
    if (this.mapService.mapMarkers.length) {
      console.log(this.mapService.mapMarkers);
      this.mapService.centerMapOnMarkers();
    }

  }
  
  displayCoord(sensor, device) {
    let iconUrl;
    let label;
    let profile
    const infoWindowDetails = [];
    if (sensor && sensor.representation) {
      profile = this.getProfile(sensor.representation.map.profile, sensor.value_coord);
  
      if (profile && profile.show_icon) {
        iconUrl = {
          url: profile['img'],
          scaledSize: {
            width: 30,
            height: 30
          }
        };
      }
  
      if (sensor.representation.map.text && profile && profile.show_text) {
        label = {
          className: 'custom-white-marker-label',
          'text': sensor.representation.map.text,
          'color': profile.color,
          'font-size': '14px',
          'background-color': 'red'
        };
      }
    }
  
      infoWindowDetails.push({ img: null, text: sensor.value_float ? sensor.value_float.toFixed(2) : device, link: 'devices/view/'+device});
  
      if (sensor.value_coord) {
        const newMarker = {
          lat: sensor.value_coord.coordinates[1] + (profile ? profile.translate_y : 0),
          lng: sensor.value_coord.coordinates[0] + (profile ? profile.translate_x : 0),
          // zoom: sensor.value_coord.coordinates[2],
          label: label,
          iconUrl: iconUrl ? iconUrl : '',
          infoWindowDetails: infoWindowDetails
        };
  
        // Check for duplicates before pushing
        if (!this.isDuplicateMarker(newMarker)) {
          this.mapService.mapMarkers.push(newMarker);
  
          if (this.mapService.mapMarkers.length) {
            this.mapService.centerMapOnMarkers();
          }
        }
      }
    
  }
  
  // Helper function to check for duplicates
  isDuplicateMarker(newMarker) {
    return this.mapService.mapMarkers.some(existingMarker =>
      existingMarker.lat === newMarker.lat &&
      existingMarker.lng === newMarker.lng
    );
  }
  

  public addUnitToValue(unitId, value) {
    let unit = ''
    if(value) {
      unit = value.toString();
      this.unitTypes.forEach(u => {
        if(u.id == unitId) {
          unit = unit + '<span>' + (u.unit ? u.unit : '') + '</span>';
        }
      })
    }
    return unit;
  }

  public findName(objectId, unitId) {
    let unit = '';
    let obj = '';
    this.unitTypes.forEach(u => {
      if(u.id == unitId) {
        if(u.name != 'null') {
          unit = u.name
        }
      }
    })
    this.objects.forEach(o => {
      if(o.id == objectId) {
        if(o.extraText != 'null') {
          obj = o.extraText
        }
      }
    })
    return (obj ? obj : '') +' <span>'+ (unit ? unit : '') + '</span>'
  }

  getProfiles() {
    // let url = this.widgetsService.getUrl('ui_profiles/');
    // this.cacheService.delete(url);
    if(this.showHeader) {
      this.widgetsService.getProfiles().subscribe(data => {
        this.profiles = data.results;
      })
    }
  }

  getDeviceDataColumns(group = '') {
    if(this.showHeader) {
      group = group ? group: '';
      this.itemList = [];
      const url = this.objectService.getUrl('adv_data/columns/')
      this.objectService.getAdvDeviceDataColumns({ page: this.pageNumber, search: this.searchString, group }).subscribe(data => {
        this.cacheService.delete(url+'group='+group);
        this.deviceColumns = data.body.results;
        const sensorIds = [];
        this.deviceColumns.forEach(column => {
          this.itemList.push({sensor_ref: column.sensor_ref.id, ui_sort: column.ui_sort, ui_sortorder: column.ui_sortorder});
          sensorIds.push(column.sensor_ref.id)
        });
        // this.sensorForm.get('sensorIds').setValue(sensorIds);
      })
    } else {
      this.deviceColumns.forEach(column => {
        this.itemList.push({sensor_ref: column.sensor_ref.id, ui_sort: column.ui_sort, ui_sortorder: column.ui_sortorder});
      });
    }
  }

  getAllDeviceDataColumns() {
    if(this.showHeader) {
      // const url = this.objectService.getUrl('adv_data/columns/')
      this.objectService.getAdvDeviceDataColumns({filter: 'all'}).subscribe(data => {
        // this.cacheService.delete(url+'filter=all');
        this.allDeviceColumns = data.body.results;
        this.filteredColumns = this.allDeviceColumns.map(column => ({
          ...column,
          highlighted: false
        }));
      })
    }
  }

  cancelOrder(header) {
    const currentItem = this.itemList.find(item => item.sensor_ref === header.sensor_ref.id);
    if (!currentItem) return;
    const currentSortOrder = currentItem.ui_sortorder;
    if (currentSortOrder > 0) {
        currentItem.ui_sortorder = 0;
        this.itemList.forEach(item => {
            if (item.ui_sortorder > currentSortOrder) {
                item.ui_sortorder -= 1;
            }
        });
    }
    this.objectService.postColumns(this.itemList, this.sensorGroupTypeId || '').subscribe(data => {
      if(data) {
        this.toastrService.success('Header updated');
        console.log(data);
        this.deviceColumns = data.body.results;
        localStorage.setItem('currentSensorGroup', this.sensorGroupTypeId);
        this.getDeviceData(this.sensorGroupTypeId);
        this.currentView.emit(this.sensorGroupTypeId);
      }
    });
}

changeUiSort(header) {
  header.ui_sort = (header.ui_sort + 1) % 3;
  console.log(header.ui_sort);
  if (header.ui_sortorder === 0) {
      header.ui_sortorder = 1;
      this.itemList.forEach(element => {
          if (element.ui_sortorder > 0) {
              element.ui_sortorder += 1;
          }
      });
  }
  this.itemList.forEach(element => {
      if (element.sensor_ref === header.sensor_ref.id) {
          Object.assign(element, { ui_sort: header.ui_sort, ui_sortorder: header.ui_sortorder });
      }
  });
  this.objectService.postColumns(this.itemList, this.sensorGroupTypeId || '').subscribe(data => {
      if(data) {
          this.toastrService.success('Header updated');
          console.log(data);
          this.deviceColumns = data.body.results;
          localStorage.setItem('currentSensorGroup', this.sensorGroupTypeId);
          this.getDeviceData(this.sensorGroupTypeId);
          this.currentView.emit(this.sensorGroupTypeId);
      }
  });
}

hasMultipleSortOrders(): boolean {
  return this.deviceColumns.filter(item => item.ui_sortorder > 0).length >= 2;
}

  getDeviceData(group?:any, lot?: any) {
    this.isLoading = true;
    this.deviceData = [];
    this.mapService.mapMarkers = [];
    const params = {
      page: this.pageNumber,
      page_size: this.limit,
      search: this.searchString,
      lot : lot ? lot : '',
      group: group ? group : ''
    }
    const url = this.objectService.getUrl('adv_data/');
    // if(this.searchString) { Object.assign(params, {search : this.searchString}) }
    this.objectService.getAdvDeviceData(params).subscribe(data => {
      this.deviceData = data.body.results;
      this.isLoading = false;
      this.cacheService.delete(url+'page='+ this.pageNumber +'&page_size='+ this.limit + '&search=' +params.search+ '&lot=' +params.lot+'&group='+params.group);
      if(this.deviceData) {
        // this.displayAllCoords();
        this.getScrollPosition();
        if(data.body.next == null) {
          this.getMoreData = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          this.deviceNextToken = {limit: params['limit'], offset: params['offset'], search: params['search'] ? params['search'] : '', lot: params['lot'] ? params['lot'] : '', group: params['group']};
          this.count = data.body.count;
        this.deviceNextToken = data.body.next ? this.globalRegistry.getQueryStringParams(data.body.next.split('?')[1]) : null;
        const urlWithLimit = this.objectService.getUrl('adv_data/') + 'page=' + this.pageNumber + '&search=' + this.searchString + '&deep=' + true;
        this.cacheService.delete(urlWithLimit);
        }
      }
    })
  }

  reroute() {
    if(this.showHeader) {
      this.router.navigate(['pages/devices']);
    } else {
      this.setTriggerSensorGroupTypes.emit(false);
    }
  }

  onDragStart(event: DragEvent, index: number) {
    this.draggedItemIndex = index;
  }
  
  allowDrop(event: DragEvent) {
    event.preventDefault();
  }
  
  onDrop(event: DragEvent) {
    event.preventDefault();
    const targetIndex = this.findIndexFromEvent(event);
  
    if (targetIndex !== -1 && this.draggedItemIndex !== targetIndex) {
      this.moveItem(this.deviceColumns, this.draggedItemIndex, targetIndex);
      this.moveItem(this.itemList, this.draggedItemIndex, targetIndex);
    }
  
    this.draggedItemIndex = undefined;
  }
  
  findIndexFromEvent(event: DragEvent): number {
    const targetElement = event.target as HTMLElement;
    const dropTarget = targetElement.closest('[draggable="true"]');
    return dropTarget ? Array.from(dropTarget.parentElement.children).indexOf(dropTarget) : -1;
  }
  
  moveItem(array: any[], fromIndex: number, toIndex: number) {
    const [removedItem] = array.splice(fromIndex, 1);
    array.splice(toIndex, 0, removedItem);
  }

  onFilterChange(value: string) {
    console.log('Filter Value:', value); // Log the filter value
    this.filteredColumns = this.allDeviceColumns.map(column => ({
      ...column,
      highlighted: column.name.toLowerCase().includes(value.toLowerCase())
    }));
    console.log(this.filteredColumns);
  }  

  ngAfterViewChecked() {
    if (!this.isScrolled && this.deviceContainer && this.scrollPosition > 0) {
      // const contentHeight = this.deviceContainer.nativeElement.scrollHeight;
      if (this.scrollPosition > this.divHeight) {
        this.deviceContainer.nativeElement.scrollTop = this.scrollPosition + this.divHeight;
        this.isScrolled = true;
      }
    }
  }

  getSensorGroupTypes() {
    if(this.showHeader) {
      const url = this.entityService.getUrl('profiles/');
      this.entityService.getProfiles({type: 'sensors'}).subscribe(data => {
        this.cacheService.delete(url);
        this.sensorGroupTypes = data.body.results;
      })
    } else {
      this.refreshSensorGroupTypes.emit()
    }
  }

  getEntityProfiles() {
    this.entityService.getProfiles({type: 'sensors'}).subscribe((data) => {
      this.sensorGroupTypes = data.body.results;
    })
  }

  sensorGroupTypeEdit(sensorGroup){
    this.sensorGroupTypeId = sensorGroup.id;
    this.sensorGroupType = sensorGroup;
    console.log(this.sensorGroupType)
    this.sensorGroupTypeForm.controls['name'].setValue(sensorGroup.name);
    this.sensorGroupTypeForm.controls['name_t'].setValue(sensorGroup.nameT);
    this.sensorGroupTypeForm.controls['color'].setValue(sensorGroup.color);
    this.showSensorGroupTypeModal(sensorGroup);
}

sensorGroupTypeDelete(sensorGroupType){
    console.log(sensorGroupType);
    if(sensorGroupType.id == this.sensorGroupTypeId) {
      this.sensorGroupType = null;
      this.sensorGroupTypeId = null;
    }
    this.typeService.deleteSensorGroupType(sensorGroupType.id).subscribe(data => {
      console.log(data);
      this.toastr.success(this.translateService.instant('sensor.deleteSensorGroupType'))
      this.getEntityProfiles();
    })
}

  changeGroup(group) {
    this.getDeviceDataColumns(group);
    this.sensorForm.get('sensorGroup').setValue(group);
    // this.userService.updateUser(body)
    const body = {
      id: this.user.id,
      profile: {
          data: {
            devices_view: {
              selected_column: group
            },
            timezone: this.user.profile.data.timezone,
            language: this.user.profile.data.language,
            allowed: this.user.profile.data.allowed
          }
      },
    };
    this.userService.updateUser(body).subscribe();
  }

  addEditTranslation(label) {
    this.label = label;
    const name = this.nameInput.nativeElement.value;
    const type: any = this.convertKeysToCamelCase(this.sensorGroupType);

    if (name === '' || type.nameT === null) {
        this.translation = null;
        this.addEditTranslationsModal.showModal();
        return;
    }

    for (const translation of this.globalRegistry.systemData.translations) {
        if (type && translation.id === type.nameT) {
            this.translation = translation;
            console.log(this.translation);
            this.addEditTranslationsModal.showModal();
            return;
        }
    }

    if (Object.keys(type).length === 0 && name) {
        const newTranslation = this.sensorGroupType.controls['name_t'].value;
        for (const translation of this.globalRegistry.systemData.translations) {
            if (translation.id === newTranslation) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
                return;
            }
        }
    }
}

  convertKeysToCamelCase(obj) {
      const camelCaseObj = {};
      for (const key in obj) {
        const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
        camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
      }
      return camelCaseObj;
    }

    public initAddsensorGroupTypeModalButtons(): void {
      const _this: DevicesListComponent = this;

      this.addEditSensorGroupTypeModalButtonPrimaryInterface = {
          clickFunction(): void {
              _this.sensorGroupTypeSubmit();
          },
          text: this.translateService.instant('save')
      };

      this.addEditSensorGroupTypeModalButtonSecondaryInterface = {
          clickFunction(): void {
              _this.addEditSensorGroupTypeModal.hideModal();
          },
          text: this.translateService.instant('cancel')
      };
  }

  public sensorGroupTypeSubmit(){
    if(this.sensorGroupTypeForm.invalid) {
        Object.keys(this.sensorGroupTypeForm.controls).forEach((controlName: string) =>
            this.sensorGroupTypeForm.controls[controlName].markAsTouched()
        );
        return;
    }
    if(this.sensorGroupTypeId){
        this.editSensorGroupType(this.sensorGroupTypeForm.value, this.sensorGroupTypeId);
        this.addEditSensorGroupTypeModal.hideModal();
        
    } else {
        this.addSensorGroupType(this.sensorGroupTypeForm.value)
        this.addEditSensorGroupTypeModal.hideModal();
        
    }
  }

  public addSensorGroupType(sensorGroup): void {
    this.entityService.addProfile(sensorGroup, 'sensors').subscribe((data) => {
      if(data) {
        this.sensorGroupType = data.body.results;
        this.sensorGroupTypeId = this.sensorGroupType.id;
        this.sensorForm.get('sensorGroup').setValue(this.sensorGroupTypeId);
        this.changeGroup(this.sensorGroupTypeId)
      }
        this.toastr.success(this.translateService.instant('sensor.addSensorGroupType'));
        this.getEntityProfiles();
        this.closeCombo = true;
    });    
}

public editSensorGroupType(sensorGroup, sensorGroupTypeId: number): void {
        this.entityService.updateProfile(sensorGroupTypeId, sensorGroup, 'sensors').subscribe((data) => {
        if(data) {
          this.sensorGroupType = data.body.results;
          this.sensorGroupTypeId = this.sensorGroupType.id;
          const index = this.sensorGroupTypes.findIndex(type => type.id === sensorGroupTypeId);
          if (index !== -1) {
            this.sensorGroupTypes[index] = data.body.results;
          }
          this.sensorForm.get('sensorGroup').setValue(this.sensorGroupTypeId);
          this.changeGroup(this.sensorGroupTypeId)
        }
        this.toastr.success(this.translateService.instant('sensor.updateSensorGroupType'));
        this.getEntityProfiles();
        this.closeCombo = true;
    });    
}

  public showSensorGroupTypeModal(sensorGroup?:any): void {
      if(sensorGroup) {
          this.sensorGroupType = sensorGroup
          this.sensorGroupTypeId = sensorGroup.id;
      } else {
        this.sensorGroupType = null
        this.sensorGroupTypeId = null;
        this.initSensorGroupTypeForm();
      }
      this.addEditSensorGroupTypeModal.showModal();
  }

  onAddUpdateTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.sensorGroupTypeForm.controls['name_t'].setValue(translation.id);
    this.sensorGroupTypeForm.controls['name'].setValue(translation[this.langString]);
    this.addEditTranslationsModal.hideModal();
  }

  onResize(width: number, columnId: string) {
    this.columnWidths[this.deviceColumns.findIndex(col => col.id === columnId)] = width;
    // console.log(`Column ${columnId} resized to: ${width}px`);
  }
  
  onResizeEnd(columnId: string) {
    const width = this.columnWidths[this.deviceColumns.findIndex(col => col.id === columnId)];
    console.log(`Resizing of column ${columnId} ended with final width: ${width}px`);

    console.log(this.deviceColumns);
    console.log(this.itemList);
    this.itemList.forEach(item => {
      if(item.sensor_ref === columnId) {
        Object.assign(item, {ui_colsize: `${width}px`})
      }
    });

    this.objectService.postColumns(this.itemList, this.sensorGroupTypeId ? this.sensorGroupTypeId : '').subscribe(data => {
      if(data) {
        this.toastrService.success('Header updated');
        console.log(data);
        this.deviceColumns = data.body.results;
        localStorage.setItem('currentSensorGroup', this.sensorGroupTypeId);
        this.getDeviceData(this.sensorGroupTypeId);
        this.currentView.emit(this.sensorGroupTypeId);
      }
    })
  }  

  mapClicked($event) {
    console.log($event)
  }

  mapReady(event) {
    console.log(event);
    this.setCurrentPosition();
  }

  private setCurrentPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.currentCenter.lat = position.coords.latitude;
        this.currentCenter.lng = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  markerDragEnd(m: marker, $event) {
    console.log("dragEnd", m, $event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('triggerSensorGroupTypes') && this.triggerSensorGroupTypes) {
      this.showConfigModal();
    }
    if(changes.hasOwnProperty('deviceColumns') && this.deviceColumns.length) {
      this.deviceColumns.forEach(column => {
        this.itemList.push({sensor_ref: column.sensor_ref.id, ui_sort: column.ui_sort, ui_sortorder: column.ui_sortorder});
      });
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

}
