
<span *ngIf="isLoading" class="loader">
  <i></i>
</span>
  <ng-container *ngFor="let activity of activities">
    <div class="cards-grid">
      <fap-activities-card
        [activity]="activity"
        [equipments]="equipments"
        (deleteActivity)="deleteActivity($event)"
        [activityTypes]="activityTypes"
        [planTypes]="planTypes"
        [persons]="persons"
        (selectActivity)="getActivity($event)"
      ></fap-activities-card>
    </div>
  </ng-container>

  <fap-modal #addEditActivityModal [inputIsLarge]="true" [inputTitle]="'activity.addEditActivity' | translate" [showFooter]="false">
    <fap-activities-add-edit-layout 
      [activity]="selectedActivity" 
      [activityTypes]="activityTypes"
      [equipments]="equipments"
      [planTypes]="planTypes"
      [activities]="activities"
      [farms]="globalRegistry?.systemData?.farms"
      [lots]="globalRegistry?.systemData?.lots"
      [formTypes]="formTypes"
      (closeModal)="addEditActivityModal.hideModal(); selectedActivity = null"
      (deleteActivity)="deleteActivity($event)"
      (submitActivity)="submitActivity($event)"
      (updateActivity)="updateActivity($event)"
      (baselineActivity)="assignBaselineActivity($event)"
      (triggerBaselineActivity)="setBaselineTrigger($event)"
    >
    </fap-activities-add-edit-layout>
  </fap-modal>

<fap-modal #addEditActivityModal
    [inputPrimaryButtonInterface]="removeActivityModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="removeActivityModalButtonSecondaryInterface"
    inputTitle="{{ 'activity.removeActivity' | translate }}">
    <span class="remove-person-text">
        {{ 'people.areYouSureYouWantToRemove' | translate }}
        <b>{{ selectedActivity?.name }}</b>
    </span>
</fap-modal>

<fap-modal #addEditBaselineActivityModal [inputIsLarge]="true" [inputTitle]="'activity.initialPlanning' | translate" [showFooter]="false">
    <fap-activities-add-edit-layout 
      [activity]="baselineActivity"
      [baselineActivityId]="baselineActivityId" 
      [activityTypes]="activityTypes" 
      [equipments]="equipments"
      [planTypes]="planTypes"
      [activities]="activities"
      [isBaselineActivity]="true"
      [farms]="globalRegistry?.systemData?.farms"
      [lots]="globalRegistry?.systemData?.lots"
      [formTypes]="formTypes"
      (closeModal)="addEditBaselineActivityModal.hideModal(); baselineActivityId = null"
      (deleteBaselineActivity)="deleteBaselineActivity($event)"
      (submitBaselineActivity)="submitBaselineActivity($event)"
      (updateBaselineActivity)="updateBaselineActivity($event)"
    >
    </fap-activities-add-edit-layout>
  </fap-modal>