import { NoteModel } from '../../../../../core/models/notes/note.model';
import { Router } from '@angular/router';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { NoteTypeModel } from '../../../../../core/models/type/note-type.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { NotesInterface } from '../../../../../core/interfaces/notes/notes.interface';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NotesService } from './../../../../../core/services/api/farm/notes.service';
import { TypesService } from './../../../../../core/services/api/types/types.service';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import * as moment from "moment";

import {
    Component,
    Input,
    SimpleChanges,
    Output,
    EventEmitter,
    ViewChild,
    OnInit,
    OnChanges,
    OnDestroy,
    ElementRef
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl
} from '@angular/forms';
import { FapModalComponent } from '../../../../../shared/partials';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { NavService } from '../../../../../shared/services/nav.service';
import { Subscription } from 'rxjs';
import { AttributeEntityEnum } from '../../../../../core/enums/attribute/attribute-entity.enum';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { environment } from 'src/environments/environment';
import { MapService } from 'src/app/shared/layout/fap_main-map/service/map-service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { PlanModel } from '../../../../../core/models/company/plan.model';
import { PlanTypeModel } from '../../../../../core/models/company/plan.type.model';


export const DATE_TIME_FORMAT = {
    parse: {
      dateInput: 'l, LT',
    },
    display: {
      dateInput: 'l, LT',
      monthYearLabel: 'MM yyyy',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
}
@Component({
    selector: 'fap-edit-notes',
    templateUrl: './fap_notes-edit.component.html',
    styleUrls: ['./fap_notes-edit.component.scss'],
    providers: [{provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT}]
})
export class FapNotesEditComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public title: string;

    @Input()
    public note: NoteModel;

    @Input()
    public lots: Array < LotModel > ;

    @Input()
    public farms: Array < FarmModel > ;

    @Input()
    public types: Array < NoteTypeModel > ;

    @Input()
    public isPreviewMode = false;

    @Input()
    public noteId: number;

    @Input() public notes: Array<NoteModel> = [];

    @Input()
    public planTypes: Array<PlanTypeModel>;

    @Output()
    public submitNote: EventEmitter < FormData > = new EventEmitter();

    @Output()
    public updateNote: EventEmitter < FormData > = new EventEmitter();
    
    @Output()
    public deleteNote: EventEmitter < any > = new EventEmitter();

    @Output()
    public scrolledDownNotes: EventEmitter<any> = new EventEmitter();

    @Output()
    public filterNotes: EventEmitter<any> = new EventEmitter();
    public isEdit = false
    public mediaUrl = environment.mediaUrl

    @ViewChild('addNoteTypeModal')
    public addNoteTypeModal: FapModalComponent;
   
    @ViewChild('addEditTranslationsModal')
    public addEditTranslationsModal: FapModalComponent;
    public notesForm: UntypedFormGroup;
    public notesTypeForm:UntypedFormGroup;

    public assignedTo: PersonModel;
    public subscription: Subscription = new Subscription();
    // public lotGroup:any = [];
    public initialNoteForm: NotesInterface;
    public entityRelation:AttributeEntityEnum = AttributeEntityEnum.Note;
    public enityRelations: AttributeEntityEnum.Note = AttributeEntityEnum.Note;
    public addNoteTypeModalButtonPrimaryInterface: FapModalButtonInterface;
    public addNoteTypeModalButtonSecondaryInterface: FapModalButtonInterface;
    public noteTypeId: any;
    public noteType: NoteTypeModel;
    public isPerson=false;
    public personList:any;
    public imgData:any=[];
    public untiltimeVal:any;
    public timestamptimeVal:any;
    public minDate = new Date();
    public maxDate = null;
    public notesCoords: any;
    public coordsString: string;
    public noteImages:any = [];
    public noteImagesId: any = [];
    public translatedNames:any = [];
    public langString: string;
    public currentNoteType: NoteTypeModel;
    public mapIconUrl = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
    @ViewChild('addEditNameTranslationsModal')
    public addEditNameTranslationsModal: FapModalComponent;
    @ViewChild('nameInput1', { static: true }) nameInput1: ElementRef;
    public nameT = null;
    public localLots = [];
    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};
    @ViewChild('fileUploader') fileUploader:ElementRef;
    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
    public prefix = 'NOTES_';
    public translation = null;
    public entityInfo: any;
    public formType;
    public formFields;
    public label: string;
    public closeCombo: boolean = true;
    public formTypes = [];
    public objectId: number = null;
    public plans: Array<PlanModel> = [];
    public plan: PlanModel = null;
    public planId: number = null;
    @ViewChild('addEditPlanModal')
    public addEditPlanModal: FapModalComponent;
    
    constructor(public navService: NavService,
                public formBuilder: UntypedFormBuilder,
                public toastr: ToastrService,
                public router: Router,
                public translateService: TranslateService,
                public noteService: NotesService,
                public typesService: TypesService,
                public confirmModalService:ConfirmModalService,
                public companyService:CompanyService,
                public globalRegistryService: GlobalRegistryService,
                public cacheService: CacheResolverService,
                public mapService: MapService,
                public attributeService: AttributeService
                ) {
        this.initAddNoteTypeModalButtons();
        this.personList = globalRegistryService.systemData.persons
    }

    changeLotFormat(lotIds) {
        // console.log(lotIds);
        // let uniq = [...new Set(lotIds)];
        const lots = [];
        lotIds.forEach(element => {
            if(typeof(element) === 'number') {
            const lot = this.lots.filter(l => {
                if(l.id === element) {
                    return l;
                }
            });
            lots.push(lot[0]);
        }});
        // console.log(lots);
        const uniqueLots = lots.filter(function(item, pos) {
            return lots.indexOf(item) == pos;
        })
        if(this.note) {
            this.note.lots = uniqueLots;
        }
        this.selectedItems = uniqueLots;
        return uniqueLots
    }

    get f() {
        return this.notesForm.controls;
      }

      public getFormTypes() {
        const url = this.typesService.getUrl('form/');
        this.cacheService.delete(url);
        this.typesService.getFormTypes().subscribe((data) => {
            this.formTypes = data.body.results;
            console.log(data.body.results);
        });
      }

      public getFormTypeById(typeId: number) {
        if(!typeId) return
        const url = this.typesService.getUrl('form/');
        this.cacheService.delete(url+typeId);
        this.typesService.getFormTypeById(typeId).subscribe(data => {
          console.log(data);
          this.entityInfo = data.body.results;
        })
      }

      public minValueChanged(ev) {
        this.minDate = ev.target.value;
        let date = ev.target.value;
        const parsedDate = moment(date, 'DD/MM/YYYY, HH:mm');
        const outputDateString = parsedDate.format('YYYY-MM-DDTHH:mm:ss');
        this.notesForm.get('timestamp').setValue(outputDateString);
    }

    public maxValueChanged(ev) {
        this.maxDate = ev.target.value;
        let date = ev.target.value
        const parsedDate = moment(date, 'DD/MM/YYYY, HH:mm');
        const outputDateString = parsedDate.format('YYYY-MM-DDTHH:mm:ss');
        this.notesForm.get('until').setValue(outputDateString);
    }

    public initAddNoteTypeModalButtons(): void {
        const _this: FapNotesEditComponent = this;

        this.addNoteTypeModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.noteTypeSubmit();
            },
            text: this.translateService.instant('save')
        };

        this.addNoteTypeModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.addNoteTypeModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
    }

    public showNoteTypeModal(newCheck?:boolean): void {
        if(newCheck) {
            this.noteType = null
            this.noteTypeId = null;
            this.initNoteTypeForm();
        }
        this.addNoteTypeModal.showModal();
    }

    public initNoteTypeForm() {
        this.notesTypeForm = this.formBuilder.group({
            name: [this.noteType ? this.noteType.name : '', Validators.compose([
                Validators.required,
            ])],
            name_t: [this.noteType ? this.noteType.nameT : '', Validators.compose([
                Validators.required,
            ])],
            color: [this.noteType ? this.noteType.color : ''],
            form_type: [this.noteType ? this.noteType.formType : null],
        });
    }

    public ngOnInit(): void {
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistryService.systemData.translations;
        console.log(this.farms);
        console.log(this.lots);
        const result = this.lots.map((item) => {
            return {
                ...item,
                farms: this.farms.filter((el) => el.id === item.farm),
            };
        });
       ;
        console.log(this.note);
        this.initForm();
        this.initNoteTypeForm();
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'Deselect All',
            itemsShowLimit: 3000,
            allowSearchFilter: true
          };
        this.noteService.notesCoords.subscribe(val => {
            if(val!='') {
                this.notesCoords = val;
                this.coordsString = val.lng+','+val.lat;
                console.log(this.coordsString);
            }
        });
        
        this.subscription.add(this.navService.emitSubmit.subscribe((value: boolean) => {
            value && this.submitForm();
            this.updateAttributes();
        }));

        this.subscription.add(this.navService.emitDelete.subscribe((value: boolean) => {
            value && this.deleteCurrentNote();
        }));
        this.getFormTypes();
    }

    public updateAttributes() {
        if(this.formType && this.formFields.length > 0) {
            this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
                console.log(data);
            })
        }
    }

    public getFarmName(farmid) {
        let farm;
        this.farms.forEach(element => {
            if(element.id == farmid) {
                farm = element
            }            
        });
        return farm.name;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'note') && this.note) {
            console.log(this.note);
            this.initForm();
        }
    }

    public deleteCurrentNote() {
        console.log(this.note.id);
        this.deleteNote.emit(this.note.id);
        setTimeout(()=> {
            this.router.navigate(['/pages/notes']);
        }, 1000);
    }

    public submitForm(): void {
        console.log(this.coordsString);
        if(this.coordsString) {
            const newValue = this.coordsString.replace(',', ' ');
            const newValue1 = `Point(${newValue})`;
            this.notesForm.get('coords').patchValue(newValue1);
        }
        
        console.log(this.notesForm.value);
        if (this.notesForm.get('timestamp').value == null) {
            this.toastr.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
            return;
        }
        
        let timestamp;
        let until;
        if (typeof this.notesForm.value['timestamp'] === 'string') {
            const dateTime = this.notesForm.value['timestamp']
                ? moment(this.notesForm.value['timestamp']).format('YYYY-MM-DDTHH:mm:ss')
                : null;
            timestamp = dateTime;
        } else {
            const dateTime = this.notesForm.value['timestamp']
                ? moment(this.notesForm.value['timestamp']).format('YYYY-MM-DDTHH:mm:ss')
                : null;
            timestamp = dateTime;
        }

        if (typeof this.notesForm.value['until'] === 'string') {
            const dateTime = this.notesForm.value['until']
            ? moment(this.notesForm.value['until']).format('YYYY-MM-DDTHH:mm:ss')
                : '';
            until = dateTime;
        } else {
            const dateTime = this.notesForm.value['until']
                ? moment(this.notesForm.value['until']).format('YYYY-MM-DDTHH:mm:ss')
                : null;
            until = dateTime;
        }
        this.notesForm.get('timestamp').patchValue(timestamp);
        this.notesForm.get('until').patchValue(until);
        if(this.notesForm.get('until').value == 'Invalid date') {
            this.notesForm.get('until').patchValue(null);
            console.log(this.notesForm.get('until').value);
        }
        console.log(this.notesForm.value);
        this.note ? this.updateNote.emit(this.notesForm.value) : this.submitNote.emit(this.notesForm.value);
        setTimeout(()=> {
            this.router.navigate(['/pages/notes']);
        }, 1000);
    }

    public noteTypeSubmit(){
        if(this.notesTypeForm.invalid) {
            Object.keys(this.notesTypeForm.controls).forEach((controlName: string) =>
                this.notesTypeForm.controls[controlName].markAsTouched()
            );
            return;
        }
        if(this.noteTypeId){
            this.editNoteType(this.notesTypeForm.value, this.noteTypeId);
            this.addNoteTypeModal.hideModal();
            
        } else {
            this.addNoteType(this.notesTypeForm.value)
            this.addNoteTypeModal.hideModal();
            
        }
    }

    setFormType(formType) {
        this.notesTypeForm.get('form_type').setValue(formType.id);
        if(this.noteType.formType === formType.id || this.noteType.formType == null) {
            this.getFormTypeById(formType.id);
        } else {
            this.confirmModalService.openConfirmModal(
                this.translateService.instant('forms.changeFormType'),
                this.translateService.instant('forms.changeFormTypeMessage'));
                this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
                    if (confirmed) {
                        if(formType.id) {
                            this.objectId = formType.id;
                            this.notesTypeForm.get('form_type').setValue(formType.id);
                            this.getFormTypeById(formType.id);
                        }
                    }
                }
            );
        }
      }

    public reloadNoteTypes() {
        this.types = [];
        const url = this.typesService.getUrl('note/');
        this.cacheService.delete(url);
        this.typesService.getNoteTypes().subscribe((types:ResponseModel<NoteTypeModel[]>) => {
            this.types = types.model;
            console.log(this.types);
        })
    }

    onAddUpdateTranslation(translation) {
        console.log(translation);
        this.translation = translation;
        this.notesTypeForm.controls['name_t'].setValue(translation.id);
        this.notesTypeForm.controls['name'].setValue(translation[this.langString]);
        this.addEditTranslationsModal.hideModal();
    }

    public addNoteType(note: FormData): void {
            this.typesService.addNoteType(note).subscribe((data) => {
                this.toastr.success(this.translateService.instant('notes.createNoteType'));
                this.types.unshift(data.model);
                if(note['form_type']) {
                    this.objectId = note['form_type'];
                    this.getFormTypeById(note['form_type']);
                }
                this.notesForm.get('type').setValue(data.model.id);
                this.closeCombo = true;
            }, (error) => {
                this.toastr.error(error.error.results.error);
            });    
    }

    public editNoteType(note: FormData, noteTypeId: number): void {
                this.typesService.updateNoteType(note, noteTypeId).subscribe((data) => {
                this.toastr.success(this.translateService.instant('notes.updateNoteType'));
                const index = this.types.findIndex(type => type.id === noteTypeId);
                if (index !== -1) {
                    this.types[index] = data.model;
                }
                this.notesForm.get('type').setValue(data.model.id);
                if(note['form_type']) {
                    this.objectId = note['form_type'];
                    this.getFormTypeById(note['form_type']);
                }
                this.closeCombo = true;
            }, (error) => {
                this.toastr.error(error.error.results.error);
            });    
    }

    public deleteNoteType(noteTypeId: number): void {
        this.typesService.deleteNoteType(noteTypeId).subscribe(() => {
            // this.noteService.getNotesList.next();
            this.toastr.success(this.translateService.instant('notes.deleteNoteTypeSuccessfully'));
            this.reloadNoteTypes();
        });     
    }

    verifyFormType(noteType: NoteTypeModel) {    
        if(noteType.formType) {
            this.confirmModalService.openConfirmModal(
            this.translateService.instant('forms.changeFormType'),
            this.translateService.instant('forms.changeFormTypeMessage'));
            this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
                if (confirmed) {
                    if(noteType.formType) {
                        this.objectId = noteType.formType;
                        this.notesForm.get('type').setValue(noteType.id);
                        this.getFormTypeById(noteType.formType)
                    }
                }
            });
        } else {
            this.entityInfo = null;
            this.objectId = null;
        }
      }
    
    noteTypeEdit(note: NoteTypeModel){
        this.noteTypeId= note.id;
        this.noteType = note;
        console.log(this.noteType)
        this.notesTypeForm.controls['name'].setValue(note.name);
        this.notesTypeForm.controls['name_t'].setValue(note.nameT);
        this.notesTypeForm.controls['color'].setValue(note.color);
        this.showNoteTypeModal();
    }

    noteTypeDelete(note){
        this.deleteNoteType(note.id);           
    }

    updateItems(lots) {
        this.notesForm.get('lots').setValue(lots);
    }

    public enableEdit(): void {
        this.isPreviewMode = false;
        this.notesForm.enable();
    }

    public getCoords(coords) {
        if(coords) { return coords.coordinates[0] + ', ' + coords.coordinates[1]}
    }

    private initForm(): void {
        const formBody: {} = {
            name: [this.note ? this.note.name : null, Validators.compose([Validators.required])],
            name_t: [this.note ? this.note.nameT : null, Validators.compose([Validators.required])],
            type: [this.note ? this.note.type : null, Validators.compose([Validators.required])],
            timestamp: [this.note ? moment(this.note.timestamp).format('') : moment(new Date()).format(''), Validators.compose([Validators.required])],
            until: [this.note ? moment(this.note.until).format('') : null],
            noAction: [this.note ? this.note.noAction : null],
            info: [this.note ? this.note.info : null],
            active: [this.note ? this.note.active : true],
            images: [this.note ? this.note.images : []],
            assigned_to:[this.note && this.note.assignedTo ? this.note.assignedTo : null],
            timestampTime:[this.note ? moment(this.note.timestamp).format('LT') : null],
            untilTime:[this.note ? moment(this.note.until).format('LT') : null],
            coords:[this.note ? this.getCoords(this.note.coords) : null],
            parent: [this.note ? this.note.parent : null],
            plan: [this.note ? this.note.plan : null]
        };

        if (this.note) {
            this.noteImages = this.note.images;
            console.log(this.note);
            this.assignedTo = Object.assign({firstName:this.note.assignedTo?.first_name},this.note.assignedTo);
            formBody['id'] = this.note.id;
            this.selectedItems = this.note.lots;
            this.imgData = this.note.images;  
            if(this.note.coords) {
                this.coordsString = this.note.coords.coordinates.toString();
                this.notesForm.get('coords').patchValue(this.coordsString);
                    const markerInterface = {
                      lat: this.note.coords.coordinates[1],
                      lng: this.note.coords.coordinates[0],
                      iconUrl: this.mapIconUrl
                    }
                    this.mapService.mapMarkers.push(markerInterface);
                    this.mapService.centerMapOnMarkers();
            }
            console.log(this.coordsString);
            this.types && this.types.length && this.types.forEach(type => {
                if(type.id === this.note.type) {
                    console.log(type);
                    this.noteType = type;
                    this.objectId = this.noteType.formType;
                    this.getFormTypeById(this.noteType.formType);
                }
            });
        }
        this.notesForm = this.formBuilder.group(formBody);
        this.initialNoteForm = this.notesForm.value;
        // in preview mode we should not be able to edit or submit the form
        this.isPreviewMode ? this.notesForm.disable() : this.notesForm.enable();
    }

    setParent(note) {
        this.notesForm.get('parent').setValue(note.id);
      }

    closeModal(event:PersonModel){
        this.assignedTo = event;
        this.isPerson = false;
        this.notesForm.controls['assigned_to'].setValue(event.id ? event.id : null);
    }

    addImage(event) {
        const imageId = event.value.id;
        this.noteImages = [...this.noteImages, imageId];
        this.notesForm.get('images').setValue(this.noteImages);
        console.log(this.noteImages);
        console.log(this.notesForm.value);
    }    

    deleteNoteImage(imgId) {
        this.noteImages = this.noteImages.filter((item) => item !== imgId);
        console.log(this.noteImages);
        this.notesForm.get('images').setValue(this.noteImages);
        console.log(this.notesForm.value);
    }

    hideModal() {
        const elem = document.getElementById('custom_modal');
        const parent = document.getElementById('parent');
        elem.classList.remove('active');
        parent.classList.remove('backdrop');
    }

    ngOnDestroy(){
        this.subscription.unsubscribe();
    }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            if(t[0][this.langString] === null || t[0][this.langString] === '') {
              return translation
            } else {
              return t[0][this.langString];
            }
          } else {
            return translation
          }
      }

      convertKeysToCamelCase(obj) {
        const camelCaseObj = {};
        for (const key in obj) {
            const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) =>
                p1.toUpperCase()
            );
            camelCaseObj[camelCaseKey] =
                obj[key] instanceof Object
                    ? this.convertKeysToCamelCase(obj[key])
                    : obj[key];
        }
        return camelCaseObj;
    }

    public addPlan(plan) {
        this.globalRegistryService.systemData.plans.unshift(plan);
        this.updatePlanNote(plan.id);
        this.addEditPlanModal.hideModal();
        this.closeCombo = true;
    }

    public updatePlan(plan) {
        this.notesForm.get("plan").setValue(plan.id);
        this.plan = plan;
        const indexToUpdate = this.globalRegistryService.systemData.plans.findIndex(s => s.id === plan.id);
        if (indexToUpdate !== -1) {
        this.plan[indexToUpdate] = plan;
        }
        this.closeCombo = true;
        this.updatePlanNote(plan.id);
        this.addEditPlanModal.hideModal();
    }

    updatePlanNote(plan) {
        this.notesForm.get('plan').setValue(plan);
    }

    onDeletePlan(plan) {
        console.log(plan);
        this.toastr.error('A plan can not be deleted');
        return
        // this.companyService.deleteplan(plan.id).subscribe(data => {
        //     if(this.activityForm.get('plan').value === plan.id) {
        //         this.activityForm.get('plan').reset();
        //     }
        //     this.globalRegistry.systemData.plans = this.globalRegistry.systemData.plans.filter(obj => obj.id !== plan.id);
        //     this.toastr.success('Plan deleted successfully')
        // }, (error) => {
        //     this.toastr.error(error.error.results.error) 
        // })
    }


    addPlanModal() {
        this.plan = null;
        this.planId = 0;
        this.addEditPlanModal.showModal();
    }

    editPlan(plan) {
        console.log(plan);
        this.plan = plan;
        this.planId = plan.id;
        this.addEditPlanModal.showModal();
    }

      onAddUpdateNameTranslation(translation) {
        console.log(translation);
        this.nameT = translation;
        this.notesForm.controls['name_t'].setValue(translation.id);
        this.notesForm.controls['name'].setValue(translation[this.langString]);
        this.addEditNameTranslationsModal.hideModal();
    }

    addEditTranslation(label: string, inputElement: any, translationTarget: any, popup: any, form: any) {
        console.log(inputElement);
        if(popup === this.addEditNameTranslationsModal) {
            console.log('activity modal')
        } else {
            console.log('type modal')
        }
        this.label = label;
        const name = this[inputElement].nativeElement.value;
        const type: any = this.convertKeysToCamelCase(translationTarget);
        console.log(name);
        console.log(type);
        if (name === null || name === '' || type.nameT === null) {
            if(inputElement === 'nameInput') {
                this.translation = null;
            } else {
                this.nameT = null;
            }
            popup.showModal();
        } else {
            let found;
            if(inputElement === 'nameInput') {
                found = this.globalRegistryService.systemData.translations.find(translation => translation.id === type.nameT);
            } else {
                found = this.globalRegistryService.systemData.translations.find(translation => translation.id === type.nameT);
            }
            if(!found) {
                if(inputElement === 'nameInput') {
                    this.getTranslationByKey(type.nameT, popup);
                } else {
                    this.getTranslationByKey(type.nameT, popup);
                }
            } else {
                for (const translation of this.globalRegistryService.systemData.translations) {
                    if (type && translation.id === type.nameT) {
                        if(inputElement === 'nameInput') {
                            this.translation = translation;
                            console.log(this.translation);
                        } else {
                            this.nameT = translation;
                            console.log(this.nameT);
                        }
                        popup.showModal();
                        return;
                    }
                }
                
                if (Object.keys(type).length === 0 && name) {
                    const newTranslation = this[form].controls['name_t'].value;
                    for (const translation of this.globalRegistryService.systemData.translations) {
                        if (translation.id === newTranslation) {
                            if(inputElement === 'nameInput') {
                                this.translation = translation;
                                console.log(this.translation);
                            } else {
                                this.nameT = translation;
                                console.log(this.nameT);
                            }
                            popup.showModal();
                            return;
                        }
                    }
                }
            }
        }
      }

      getTranslationByKey(key, popup) {
        
          this.typesService.getTranslationByKey(key).subscribe(data => {
            if(data) {
              if(popup === this.addEditTranslationsModal) {
                this.translation = data.body.results;
                this.addEditTranslationsModal.showModal();
              } else {
                this.nameT = data.body.results;
                this.addEditNameTranslationsModal.showModal();
              }
              const index = this.globalRegistryService.systemData.translations.findIndex(item => item.id === data.body.results.id);
              if (index !== -1) {
                this.globalRegistryService.systemData.translations[index] = data.body.results;
                this.globalRegistryService.systemData.translationsMap = new Map<number, any[]>();
                this.translatedNames = this.globalRegistryService.systemData.translations;
                this.createObjectMap(this.globalRegistryService.systemData.translations, this.globalRegistryService.systemData.translationsMap);
              } else {
                this.globalRegistryService.systemData.translations.push(data.body.results);
                this.globalRegistryService.systemData.translationsMap = new Map<number, any[]>();
                this.translatedNames = this.globalRegistryService.systemData.translations;
                this.createObjectMap(this.globalRegistryService.systemData.translations, this.globalRegistryService.systemData.translationsMap);
              }
            }
          })
        
      }

      public createObjectMap(arrayOfObjects: any[], objectMap: Map<number, any>): Map<number, any> {
        arrayOfObjects.forEach(obj => {
            objectMap.set(obj.id, obj);
            if(obj.children && obj.children.length) {
               this.createObjectMap(obj.children, objectMap);
            }
        });
        return objectMap;
      }
}