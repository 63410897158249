<div class="dashboard-card dash_cont">
    <div class="d-flex">
        <div class="col-md-4 pl-0">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-6 col_lot_name" *ngIf="lotName !== ''">
            <h2>{{ lotName }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-4 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em
      > -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="dataSource.length == 0" style="display.: flex; align-items:center; justify-content: center; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 70%; width: 100%;">
        <h3 style="text-align: center;">
          <span class="loader">
            <i></i>
          </span>
      </h3>
      </div> -->
    <div class="card-content" *ngIf="dataSource.length != 0">
        <table>
            <tr *ngFor="let item of dataSource">
                <td class="icon">
                    <span *ngIf="item?.currentSensor?.icon">
                        <img [src]="mediaUrl + item.currentSensor?.icon?.file" alt="">
                    </span>
                    <span *ngIf="!item?.currentSensor?.icon && item?.unitTypes[0].icon">
                        <img [src]="mediaUrl + item?.unitTypes[0].icon?.file" alt="default placeholder">
                    </span>
                    <span *ngIf="!item?.currentSensor?.icon && !item?.unitTypes[0].icon">
                        <img src="../../../../assets/icons/sensor_microchip.svg" alt="default placeholder">
                    </span>
                </td>
                <td class="name overviewName">
                    <div class="main_text">
                        {{item?.name}}
                        <span>{{item?.devices[0]?.product?.label?.name}} {{item?.devices[0]?.id}}</span>
                    </div>
                    <div class="tooltip">{{item?.name}} <span>{{item?.devices[0]?.product?.label?.name}} {{item?.devices[0]?.id}}</span></div>
                </td>
                <td class="action_btn">
                    <button *ngIf="item?.currentSensor?.type && item?.currentSensor?.type?.groups" (click)="getFormTypes(item)">
                        <ng-container *ngFor="let group of item.currentSensor?.type?.groups">
                            <img src="../../../../assets/images/icons/link.png" alt="icon" *ngIf="group === 'actuator'">
                        </ng-container>
                    </button>
                    
                </td>
                <td class="dateTime">
                    <span *ngIf="item?.date">
                        {{item?.date}}
                    </span>
                    <span *ngIf="!item?.date">
                        --
                    </span>
                </td>
                <td class="value">
                    <span *ngIf="item?.currentSensor?.lastValue && item?.currentSensor?.lastValue?.value_coord">
                        <button (click)="displayCoord(item?.currentSensor?.lastValue?.value_coord)"><img src="../../../../assets/icons/marker-white.png" style="max-width: 12px" alt="marker"></button>
                    </span>
                    <span *ngIf="item?.value_float">
                        {{item?.value_float | number:'1.'+globalRegistry.systemData.company.digits}}
                    </span>
                    <span *ngIf="!item?.value_float && !item?.currentSensor?.lastValue?.value_coord">
                        0
                    </span>
                </td>
                <td class="unit">
                    <span>{{item.unit && item.unit !== 'null' ? item.unit : ''}}</span>
                </td>
            </tr>
        </table>
      </div>
</div>

<fap-modal #schedulerTriggerModal [inputTitle]="'scheduler.command' | translate" [showFooter]="false" [inputIsMedium]="true">
    <fap-scheduler-trigger 
        [formTypeId]="sensorData?.currentSensor?.form"
        [sensorId]="sensorData?.currentSensor?.id"
    ></fap-scheduler-trigger>
</fap-modal>

<fap-modal #addEditTranslationsPopup [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.currentSensor)" 
        prefix="SENSOR_" 
        [label]="label"
        [translation]="translation"
        [group]="'sensors'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditAutomationTranslationPopup [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.currentSensor)" 
        prefix="TRIGGER_" 
        [label]="label"
        [translation]="automationTranslation"
        [group]="'triggers'"
        (submitTranslation)="onAddUpdateAutomationTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #mapModal [showFooter]="false" [inputTitle]="'widget.location' | translate" [inputIsMediumLarge]="true">
    <div class="form-group map-container">
        <div
          class="search"
          [ngClass]="{ opened: mapSearchToggle, 'd-flex': mapTypeControl }"
        >
          <label for="search" class="fa fa-search" (click)="mapSearchToggle = true"> </label>
            <input
              matInput
              (focus)="mapSearchToggle = true"
              (focusout)="mapSearchToggle = false"
              (keydown.enter)="$event.preventDefault()"
              placeholder="search for location"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              class="form-control"
              #search
              [formControl]="searchControl"
            />
          
        </div>
        <agm-map
          [latitude]="currentCenter?.lat"
          [longitude]="currentCenter?.lng"
          [zoom]="zoom"
          [disableDefaultUI]="false"
          [zoomControl]="false"
          [scaleControl]="false"
          [streetViewControl]="false"
          [mapTypeControl]="false"
          [mapTypeId]="'hybrid'"
          (mapClick)="mapClicked($event)"
          (mapReady)="mapReady($event)"
        >
          <agm-marker
            *ngFor="let m of markers; let i = index"
            [latitude]="m.lat"
            [longitude]="m.lng"
            [label]="m.label"
            [markerDraggable]="m.draggable"
            [iconUrl]="m.iconUrl"
            [animation]="'DROP'"
            (dragEnd)="markerDragEnd(m, $event)"
          >
            <!-- [animation]="'DROP'"  -->
      
            <agm-info-window>
              <strong>{{ m.content }}</strong>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
</fap-modal>