import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    FormGroupDirective,
    Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { SensorsEnum } from '../../../../core/enums/sensors.enum';
import { WidgetsEnum } from '../../../../core/enums/widgets.enum';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { WidgetInterface } from '../../../../core/interfaces/widget/widget.interface';
import { ActivityModel } from '../../../../core/models/activity/activity.model';
import { DiseaseModel } from '../../../../core/models/diseases/disease.model';
import { LotModel } from '../../../../core/models/lot/lot.model';
import { SensorModel } from '../../../../core/models/sensor/sensor.model';
import { WidgetModel } from '../../../../core/models/widget/widget.model';
import { ActivityService } from '../../../../core/services/api/activity/activity.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { CropService } from '../../../../core/services/api/crop/crop.service';
import { ObjectModel, ObjectService } from '../../../../core/services/api/objects/object.service';
import { UnitTypeModel } from '../../../../core/services/api/unit-type/unit-type.service';
import { WidgetsService } from '../../../../core/services/api/widgets/widgets.service';
import { FapModalComponent } from '../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { NavService } from '../../../../shared/services/nav.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { SensorService } from '../../../../core/services/api/sensor/sensor.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { WidgetTypeModel } from '../../../../core/models/type/widget-type.model';

export enum SensorCodes {
    COORDS_SENSOR = 39,
    PERCENTAGE_SENSOR = 62,
}

@Component({
    selector: 'fap-add-edit-widget',
    templateUrl: './fap-add-edit-widget.component.html',
    styleUrls: ['./fap-add-edit-widget.component.scss'],
})
export class FapAddEditWidgetComponent implements OnInit, OnDestroy, OnChanges {
    public widgetForm: UntypedFormGroup;
    public sensorForm: UntypedFormGroup;
    public searchText;
    public selectedtype: string;
    public color = '#400000';
    private lastColorIndex = -1;
    public graphType = 'line';
    public graphItem
    public css = null;
    public sensorCss = null;
    public widgetsEnum = Object.keys(WidgetsEnum).map((key) => ({
        id: WidgetsEnum[key],
        name: key,
    }));
    public sensorsEnum = Object.keys(SensorsEnum).map((key) => ({
        id: SensorsEnum[key],
        name: key,
    }));

    public itemList = [];

    public filteredSensors: SensorModel[] = [];
    public filteredCoordSensors: SensorModel[] = [];
    public filteredPercentageSensors: SensorModel[] = [];
    public coordSensors: SensorModel[] = [];
    public percentageSensors: SensorModel[] = [];

    public subscriptions: Array<Subscription> = [];

    public widgetConfig: {} = {};
    public background;
    public disease;
    public device;
    public activity: ActivityModel;

    @Input() lots: LotModel[] = [];
    @Input() widgetTypes: WidgetTypeModel[] = [];

    @Input() sensors: SensorModel[] = [];
    @Input() sensorGroupTypes: any[] = [];

    @Input() resetForm = false;
    @Input() view: number;
    @Input() widgetsLength = 1;
    @Input() widget: WidgetModel;
    @Input() objects: ObjectModel;
    @Input() unitTypes: UnitTypeModel;
    @Input() public allDeviceColumns = [];
    @Input() public deviceColumns = [];

    @Output()
    public triggerSensorGroupTypes: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public addWidget: EventEmitter<any> = new EventEmitter();

    @Output()
    public updateWidget: EventEmitter<{ id: number; widget: WidgetInterface }> =
        new EventEmitter();

    @Output()
    public editForm: EventEmitter<any> = new EventEmitter();

    @Output()
    public deleteWidget: EventEmitter<number> = new EventEmitter();

    @Output()
    public cancel: EventEmitter<any> = new EventEmitter();

    @ViewChild(FormGroupDirective) widgetFormDirective: FormGroupDirective;

    public configForm: UntypedFormGroup;
    public graphConfigForm: UntypedFormGroup;
    public image: File;
    uploadFileAcceptFormats: string[] = ['image/jpeg', 'image/gif', 'image/png', 'image/svg+xml'];
    public addImageModalButtonPrimaryInterface: FapModalButtonInterface;
    public addImageModalButtonSecondaryInterface: FapModalButtonInterface;
    public configModalButtonPrimaryInterface: FapModalButtonInterface;
    public configModalButtonSecondaryInterface: FapModalButtonInterface;
    public graphConfigModalButtonPrimaryInterface: FapModalButtonInterface;
    public graphConfigModalButtonSecondaryInterface: FapModalButtonInterface;
    imageSrc: string;
    public label: string;

    public cols = [2, 3, 4, 5, 6];
    public rows = [2, 3, 4, 5, 6];
    public selectedCol;
    public sizeSmall;
    public sizeMedium;
    public sizeLarge;
    public selectedRow;
    public bgColor;
    public order;
    @Input() public sensorGroupview;
    public sensorGroup = [];
    public coordSensorGroup = [];
    public percentageSensorGroup = [];
    public finalSensorList = [];
    public diseases: Array<DiseaseModel> = [];
    public filteredDiseases: Array<any> = [];
    public translatedNames:any = [];
    public langString: string;
    public activities: Array<ActivityModel> = [];
    public devices = [];
    public getMore = true;
    public nextToken: { offset: number; limit: number; type: string; search: string} = null;
    public nextActivitiesToken: { offset: number; limit: number; search: string} = null;
    public nextDevicesToken: { offset: number; limit: number; search: string} = null;
    public limit = 20;
    public newDisease;
    public imgWidth: number;
    public imgHeight: number;
    public orientation: string;
    public mediaUrl = environment.mediaUrl;
    public nextImgToken :{ offset: number; limit: number;} = null;
    public getMoreImg = true;
    public changedImage: boolean;
    public widgetLots = [];
    public mapType = 'position';
    public form = {};
    @ViewChild('addImageModal')
    public addImageModal: FapModalComponent;
    @ViewChild('configModal')
    public configModal: FapModalComponent;
    @ViewChild('graphConfigModal')
    public graphConfigModal: FapModalComponent;
    @ViewChild('sensorConfigModal') public sensorConfigModal: FapModalComponent;
    public imgPath = '';
    public cssConfig
    @ViewChild('fileUploader') fileUploader:ElementRef;
    public widgetImages:any = null;
    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
    @ViewChild('addEditTranslationsModal')
    public addEditTranslationsModal: FapModalComponent;
    public colors = [
        'white',
        'pink',
        'blue',
        'orange',
        'green',
        'brown',
        'yellow',
    ];
    public translation = null;
    public selectedItems = [];
    public config;
    public mapTypes = ['position', 'tracking'];
    public graphConfig;
    @ViewChild('matSelect') matSelect;
    public images;
    public imageForm: UntypedFormGroup;
    public graphColors = [
        {"id": 1, "name_t": "COLOR_BLACK", "color": "#000000"},
        {"id": 2, "name_t": "COLOR_RED", "color": "#FF0000"},
        {"id": 3, "name_t": "COLOR_GREEN", "color": "#008000"},
        {"id": 4, "name_t": "COLOR_BLUE", "color": "#0000FF"},
        {"id": 5, "name_t": "COLOR_YELLOW", "color": "#FFFF00"},
        {"id": 6, "name_t": "COLOR_TEAL", "color": "#008080"},
        {"id": 7, "name_t": "COLOR_MAGENTA", "color": "#FF00FF"},
        {"id": 8, "name_t": "COLOR_GRAY", "color": "#808080"},
        {"id": 9, "name_t": "COLOR_ORANGE", "color": "#FFA500"},
        {"id": 10, "name_t": "COLOR_PURPLE", "color": "#800080"}
    ];
    public tempSettings;
    private availableColors = [...this.graphColors];
    isMobileScreen: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        public widgetServices: WidgetsService,
        public navService: NavService,
        public cropService: CropService,
        public globalRegistry: GlobalRegistryService,
        public activityService: ActivityService,
        public objectService: ObjectService,
        public cacheService: CacheResolverService,
        public companyService: CompanyService,
        public translateService: TranslateService,
        public toastrService: ToastrService,
        public sensorService: SensorService,
        private breakpointObserver: BreakpointObserver
    ) {
        this.initForm();
    }

    ngOnInit(): void {
        // setTimeout(() => {
            // console.log(this.sensors);
            // console.log(this.objects);
            // console.log(this.unitTypes);
            this.langString = localStorage.getItem('language');
            this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
                if(lang) {
                    this.langString = lang;
                }
            }));
            this.translatedNames = this.globalRegistry.systemData.translations;
            this.breakpointObserver.observe([Breakpoints.Handset])
            .subscribe((state) => {
              this.isMobileScreen = state.matches;
            });
            this.filterDeviceSensors(this.sensors);
            const coordParams = {unit: SensorCodes.COORDS_SENSOR}
            this.subscriptions.push(
            this.sensorService.getSensors(coordParams).subscribe(data => {
                this.coordSensors = data.body.results;
            })
            )
            const percentageParams = {unit: SensorCodes.PERCENTAGE_SENSOR}
            this.subscriptions.push(
            this.sensorService.getSensors(percentageParams).subscribe(data => {
                this.percentageSensors = data.body.results;
                this.filterPercentageSensors(this.percentageSensors);
            })
            )
        // }, 5000);

        const url = this.activityService.getUrl('activities/')
        this.cacheService.delete(url+'limit='+this.limit);   
        this.getDevices();
        this.subscriptions.push(
            this.navService.resetModalForm.subscribe((value: boolean) => {
                if (value) {
                    this.resetForms();
                }
            }),
            this.activityService.getActivities().subscribe(data => {
                this.nextActivitiesToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextActivitiesToken) this.getMore = true
                this.activities = data.body.results;
            })
        );
        this.initConfigModalButtons();
        this.initGraphConfigModalButtons();
        this.initConfigForm();
        this.initImageModalButtons();
        this.initImageForm();
        this.initGraphConfigForm();
        if(this.globalRegistry.systemData.user && this.globalRegistry.systemData.user.profile.data["ui"] && this.globalRegistry.systemData.user.profile.data["ui"]["graph_colors"]) {
            this.availableColors = [];
            this.graphColors = this.globalRegistry.systemData.user.profile.data["ui"]["graph_colors"];
            this.availableColors = [...this.graphColors];
        }
    }

    initImageForm() {
        this.imageForm = new UntypedFormGroup({
          file: new UntypedFormControl('', Validators.required),
          group: new UntypedFormControl('widgets', Validators.required),
          tags: new UntypedFormControl([], Validators.required),
        });
      }

      initConfigForm() {
        console.log(this.config);
        this.configForm = new UntypedFormGroup({
          font_size: new UntypedFormControl(this.config ? this.config.font_size.replace('px', '') : '16'),
          color: new UntypedFormControl(this.config ? this.config.color : '#000000'),
          top: new UntypedFormControl(this.config ? this.config.top.replace('%', '') : '50'),
          left: new UntypedFormControl(this.config ? this.config.left.replace('%', '') : '50'),
          background: new UntypedFormControl(this.widget ? this.widget.settings ? this.widget.settings['background'] ? this.widget.settings['background'] : null : null : null),
        });
      }

      initGraphConfigForm() {
        console.log(this.graphConfig);
        this.graphConfigForm = new UntypedFormGroup({
          graphType: new UntypedFormControl(this.graphConfig ? this.graphConfig.graphType : 'line'),
          color: new UntypedFormControl(this.graphConfig ? this.graphConfig.color : '400000'),
        });
      }

    public getDevices() {
        // const url = this.objectService.getUrl('devices/')
        // this.cacheService.delete(url+'limit='+this.limit);   
        this.subscriptions.push(this.objectService.getDevices().subscribe(data => {
          this.devices = data.model;
          console.log(this.devices);
          this.nextDevicesToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
        }))
      }

      convertKeysToCamelCase(obj) {
        const camelCaseObj = {};
        for (const key in obj) {
            const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) =>
                p1.toUpperCase()
            );
            camelCaseObj[camelCaseKey] =
                obj[key] instanceof Object
                    ? this.convertKeysToCamelCase(obj[key])
                    : obj[key];
        }
        return camelCaseObj;
    }

    addEditTranslation(label) {
      this.label = label;
      const name = this.nameInput.nativeElement.value;
      const type: any = this.convertKeysToCamelCase(this.widget);
  
      if (name === '' || type.nameT === null) {
          this.translation = null;
          this.addEditTranslationsModal.showModal();
          return;
      }
  
      for (const translation of this.globalRegistry.systemData.translations) {
          if (type && translation.id === type.nameT) {
              this.translation = translation;
              console.log(this.translation);
              this.addEditTranslationsModal.showModal();
              return;
          }
      }
  
      if (Object.keys(type).length === 0 && name) {
          const newTranslation = this.widgetForm.controls['name_t'].value;
          for (const translation of this.globalRegistry.systemData.translations) {
              if (translation.id === newTranslation) {
                  this.translation = translation;
                  console.log(this.translation);
                  this.addEditTranslationsModal.showModal();
                  return;
              }
          }
      }
  }

  public getImages() {
    const url = this.companyService.getUrl('images/')
    this.cacheService.delete(url);
    this.companyService.getCompanyImages().subscribe((response): void => {
        this.images = response.body["results"];
        console.log(this.images);
        this.nextImgToken = response.body.next
        ? this.globalRegistry.getQueryStringParams(response.body.next.split("?")[1])
        : null;
        if(this.nextImgToken) this.getMoreImg = true
      })
    }

    public showImageModal(): void {
        this.addImageModal.showModal();
    }

    onFileInput(event) {
        if(event.target.files && event.target.files.length) {
          if ( /\.(jpe?g|png|gif)$/i.test(event.target.files[0].name) === false ) { 
            this.toastrService.error(this.translateService.instant('image.notAnImage'))
            this.image = null;
            this.fileUploader.nativeElement.value = null;
            return
          }
          const [image] = event.target.files;
          this.image = image;
          const _URL = window.URL || window.webkitURL;
          let file, img, height, width;
          if ((file = event.target.files[0])) {
              img = new Image();
              const objectUrl = _URL.createObjectURL(file);
              img.onload = function () {
                  height = this.height;
                  width = this.width;
                  _URL.revokeObjectURL(objectUrl);
              };
              img.src = objectUrl;
          }
          setTimeout(()=> {
            if(width <= height) {
              this.orientation  = "portrait";
              console.log("portrait");
            } else {
                this.orientation  = "landscape";
                console.log("landscape");
            }
          }, 500);
        }
      }
      
      public imageUploaded(file: File): void {
        this.changedImage = true;
        this.image = file;
        console.log(this.image);
        this.imageForm.markAsDirty();
      }
      
      public initImageModalButtons(): void {
        const _this: FapAddEditWidgetComponent = this;
      
        this.addImageModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.imageSubmit();
            },
            text: this.translateService.instant('save')
        };
      
        this.addImageModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.addImageModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
      }

      public imageSubmit() {
        const data = {
          width: this.imgWidth,
          height: this.imgHeight,
          orientation: this.orientation
      }
          const formData = new FormData();
          formData.append("file", this.image);
          formData.append("group", this.imageForm.get('group').value);
          formData.append("tags", this.imageForm.get('tags').value);
          formData.append("data", JSON.stringify(data));
          console.log(formData);
          // return;
          this.companyService.postCompanyImages(formData).subscribe(data => {
              console.log(data);
              this.imageForm.reset();
              this.imageForm.clearValidators();
              this.initImageForm();
              this.widgetImages = [];
              this.imgPath = ''
              this.getImages();
          })
        this.addImageModal.hideModal();
      }
  
    onAddUpdateTranslation(translation) {
        console.log(translation);
        this.translation = translation;
        this.widgetForm.controls['name_t'].setValue(translation.id);
        this.widgetForm.controls['name'].setValue(translation[this.langString]);
        this.addEditTranslationsModal.hideModal();
    }

    filterProduct(search) {
        let type = '';
        if(this.selectedtype == 'fungal_alert') {
            type = 'fungal'
        } else if(this.selectedtype == 'pathogen_alert') {
            type = 'viral'
        } else {
            type = 'insect'
        } 
        this.subscriptions.push(this.cropService.getDiseases({type, search}).subscribe(data => {
        this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextToken) this.getMore = true;
        this.filteredDiseases = data.body.results;
        }))
      }

      filterActivity(search) {
        this.subscriptions.push(this.activityService.getActivities({search}).subscribe(data => {
        this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextToken) this.getMore = true;
        this.activities = data.body.results;
        }))
      }

      filterDevices(search) {
        this.subscriptions.push(this.objectService.getDevices({search}).subscribe(data => {
        this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextToken) this.getMore = true;
        this.activities = data.body.results;
        }))
      }

      getLocalSensors(sensorList: any[]) {
        const objects = Object.values(this.objects);
        const unitTypes = Object.values(this.unitTypes);
        const sensors = Object.values(this.sensors);
        const devices = Object.values(this.devices);
        
        return sensorList.map(sensorObj => {
            const sensor = sensors.find(s => s.id === sensorObj.id);
            if (!sensor) return null;
            return {
                unitTypes: unitTypes.filter(el => el.id === sensor.sensorRef.unit_type),
                objects: objects.filter(el => el.id === sensor.sensorRef.object_id),
                sensor: sensor,
                devices: devices.filter(el => el.id === sensor.device),
                color: sensorObj.color,
                graphType: sensorObj.graphType,
                css: sensorObj.css
            };
        }).filter(Boolean);
    } 

    ngOnChanges(changes: SimpleChanges) {
        if (Object.prototype.hasOwnProperty.call(changes, 'widget') && this.widget) {
            console.log(this.widget);
            this.initForm();
            this.itemList = this.getLocalSensors(this.widget['settings']['streams']);
            console.log(this.itemList);
            this.sizeSmall = this.widget['sizeSmall'];
            this.sizeMedium = this.widget['sizeMedium'];
            this.sizeLarge = this.widget['sizeLarge'];
            this.bgColor = this.widget['bgColor'];
            this.background = this.widget.settings ? this.widget.settings['background'] : null;
            this.widgetImages = this.widget.settings ? this.widget.settings['background'] : null;
            this.disease = this.widget.settings ? this.widget.settings['disease'] : null;
            this.activity = this.widget.settings ? this.widget.settings['activity'] : null;
            this.device = this.widget.settings ? this.widget.settings['device'] : null;
            this.mapType = this.widget.settings ? this.widget.settings['mapType'] : null;
            this.form = this.widget.settings ? this.widget.settings['form'] : null;
            this.order = this.widget.settings ? this.widget.settings['order'] : 1;
            this.sensorGroupview = this.widget.settings ? this.widget.settings['sensorGroupview'] : null
            if(this.widget['disease']) {

                this.cropService.getDisease(this.widget['disease']).subscribe(data => {
                    if(this.filteredDiseases) {
                        this.filteredDiseases.unshift(data.body.results);
                        this.newDisease = data.body.results;
                    }
                })
            }
            console.log(this.widget.settings);
        }
        if(this.background) {
            this.imgPath = this.background['file']
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    changeType(type) {
        console.log(type);
        this.itemList = [];
        this.mapType = type;
    }

    editCss(item) {
        if (this.selectedtype === 'image') {
            if (item) {
                console.log(item);
                this.sensorForm.get('sensorId').reset();
                this.sensorForm.get('sensorId').setValue(item.sensor.id);
                this.css = item.css;
                this.showConfigModal();
            }
        } else if(this.selectedtype === 'sensor_value') {
            if (item) {
                console.log(item)
                this.sensorForm.get('sensorId').reset();
                this.sensorForm.get('sensorId').setValue(item.sensor.id);
                this.sensorCss = item.css;
                this.showConfigModal();
            }
        }else {
            return;
        }
    }

    editGraphStyle(item) {
        console.log(item);
        this.graphItem = item;
        this.color = item.color;
        this.graphType = item.graphType;
        const obj = { graphType: this.graphType, color: this.color };
        this.graphConfig = obj;
        this.initGraphConfigForm();
        this.graphConfigModal.showModal();
    }

    public initForm(): void {
        this.selectedtype = this.widget
            ? this.widget['type']['uniqueid']
            : 'graph';
            console.log(this.widget);
        this.widgetForm = this.fb.group({
            name: [
                this.widget ? this.widget['name'] : null,
                Validators.required,
            ],
            name_t: [
                this.widget ? this.widget['nameT'] : null,
                Validators.required,
            ],
            lot: [
                this.widget ? this.widget.objectId : null
            ],
            sizeSmall: this.widget ? this.widget['sizeSmall'] : null,
            sizeMedium: this.widget ? this.widget['sizeMedium'] : null,
            sizeLarge: this.widget ? this.widget['sizeLarge'] : null,
            bgColor: this.widget ? this.widget['bgColor'] : null,
            type: [
                this.widget ? this.widget['type'] ? this.widget['type']['uniqueid'] : null : null,
                Validators.required,
            ],
            background: this.widget ? this.widget.settings ? this.widget.settings['background'] ? this.widget.settings['background'] : null : null : null,
            disease: this.widget ? this.widget.settings ? this.widget.settings['disease'] ? this.widget.settings['disease'] : null : null : null,
            activity: this.widget ? this.widget.settings ? this.widget.settings['activity'] ? this.widget.settings['activity'] : null : null : null,
            device: this.widget ? this.widget.settings ? this.widget.settings['device'] ? this.widget.settings['device'] : null : null : null,
            mapType: this.widget ? this.widget.settings ? this.widget.settings['mapType'] ? this.widget.settings['mapType'] : 'position' : null : null,
            form: this.widget ? this.widget.settings ? this.widget.settings['form'] ? this.widget.settings['form'] : null : null : null,
            graphType: this.widget ? this.widget.settings ? this.widget.settings['graphType'] ? this.widget.settings['graphType'] : null : null : null,
            view: this.widget ? this.widget.settings ? this.widget.settings['view'] ? this.widget.settings['view'] : null : null : null,
            order: this.widget ? this.widget.settings ? this.widget.order : 1000 : 1000,
            sensorGroupview: this.widget ? this.widget.settings ? this.widget.settings['sensorGroupview'] ? this.widget.settings['sensorGroupview'] : null : null : null
        });
        // this.widgetForm.controls.disease.setValue(this.disease);
        this.widgetForm.controls.type.setValue(this.selectedtype);
        console.log(this.widgetForm.value);
        this.changedtype(this.selectedtype);
        if(this.widget) {
            this.selectedItems = this.widget[' '];
            if(this.selectedtype === 'weather_forecast') {
                this.widgetLots = this.widget.settings['lots'];
                this.selectedItems = this.widget.settings['lots'];
            }
            if(this.widgetForm.value && this.widgetForm.value.lot) {
                this.filterSensors(this.widgetForm.value.lot);
                this.filterCoordSensors(this.widgetForm.value.lot);
                this.filterPercentageSensors(this.widgetForm.value.lot);
            }
            // if(this.widgetForm.value && this.widgetForm.value.type) {
            //     this.widgetTypes.forEach(w_type => {
            //         if(w_type.id === this.widgetForm.value.type) {
            //             this.widgetForm.controls.type.setValue(w_type.uniqueid)
            //         }
            //     });
            // }
        }
    }

    public changeCols(cols) {
        // console.log(cols);
        this.selectedCol = cols;
    }

    public changeDisease(col) {
        this.disease = col;
    }

    public changeDevice(col) {
        this.device = col;
    }

    public changeActivity(col) {
        this.activity = col;
    }

    public changesizeSmalls(col) {
        this.sizeSmall = col;
    }

    public changesizeMediums(col) {
        this.sizeMedium = col;
    }

    public changesizeLarges(col) {
        this.sizeLarge = col;
    }

    public changeBgColor(color) {
        this.bgColor = color;
    }

    public changeOrder(order) {
        this.order = order;
    }

    public changeView(view) {
        this.sensorGroupview = view;
    }

    public changeCss(event) {
        this.css = event.target.value;
    }

    public changeSensorCss(event) {
        this.sensorCss = event.target.value;
    }

    public addbackground(event) {
        this.background = event.target.value;
    }

    updateItems(lots) {
        this.widgetLots = lots;
    }

    public createSoilLevelGroup = (data = null): UntypedFormGroup =>
        this.fb.group({
            temperature: this.fb.control(
                data ? data.temperature : null,
                Validators.required
            ),
            humidity: this.fb.control(
                data ? data.humidity : null,
                Validators.required
            ),
            ph: this.fb.control(data ? data.ph : null, Validators.required),
            ec: this.fb.control(data ? data.ec : null, Validators.required),
            N: this.fb.control(data ? data.N : null, Validators.required),
            P: this.fb.control(data ? data.P : null, Validators.required),
            K: this.fb.control(data ? data.K : null, Validators.required),
        });

    public addItem(): void {
        console.log(this.sensorForm.value);
        // if (this.sensorForm.invalid || !this.color || !this.css || !this.sensorCss) return;
        const { sensorId } = this.sensorForm.value;
        this.sensorForm.get('sensorId').reset();
        const selectedSensor = this.sensors.find(
            (sensor: SensorModel) => sensor.id === +sensorId
        );
        const item = {
            sensor: selectedSensor,
            graphType: 'line',
        };

        if (this.selectedtype === 'graph') {
            const unusedColor = this.availableColors.find(color => !this.isColorUsed(color.color));

            if (unusedColor) {
              item['color'] = unusedColor.color;
              this.availableColors = this.availableColors.filter(color => color.color !== unusedColor.color);
            } else {
              item['color'] = this.getNextColor();
            }
        }
        if (this.selectedtype === 'image') {
            item['css'] = this.css;  
        }
        if (this.selectedtype === 'sensor_value') {
            item['css'] = this.sensorCss;  
        }
        this.itemList = this.itemList.filter(nitem => nitem.sensor.id != item.sensor.id);
        this.itemList.push(item);
        console.log(this.itemList);
    }

    isColorUsed(color: string): boolean {
        return this.itemList.some(item => item.color === color);
      }
    
      getRandomColor(): string {
        const randomIndex = Math.floor(Math.random() * this.graphColors.length);
        return this.graphColors[randomIndex].color;
      }

      getNextColor(): string {
        // Increment the last used color index
        this.lastColorIndex = (this.lastColorIndex + 1) % this.graphColors.length;
        return this.graphColors[this.lastColorIndex].color;
      }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            if(t[0][this.langString] === null || t[0][this.langString] === '') {
              return translation
            } else {
              return t[0][this.langString];
            }
          } else {
            return translation
        }
      }

    public removeItem(index: number): void {
        this.itemList = [
            ...this.itemList.slice(0, index),
            ...this.itemList.slice(index + 1, this.itemList.length),
        ];
    }

    public openConfig() {
        if(this.widget) {
            console.log(this.widget.settings['streams'][0]['css']);
        } else {
            console.log('new');
        }
    }

    public changedtype(valueType: string): void {
        // this.widgetForm.get('lot').reset();
        // this.disease = null;
        console.log(valueType)
        this.selectedtype = valueType;
        this.itemList = [];
        this.background = null;
        this.imgPath = null;
        if (valueType === 'soil') {
            this.widgetForm.addControl(
                'soil',
                this.fb.group(
                    {
                        level30: this.createSoilLevelGroup(
                            this.widget
                                ? this.widget.settings['options']['level30']
                                : null
                        ),
                        level15: this.createSoilLevelGroup(
                            this.widget
                                ? this.widget.settings['options']['level15']
                                : null
                        ),
                        level10: this.createSoilLevelGroup(
                            this.widget
                                ? this.widget.settings['options']['level10']
                                : null
                        ),
                        level5: this.createSoilLevelGroup(
                            this.widget
                                ? this.widget.settings['options']['level5']
                                : null
                        ),
                    },
                    [Validators.required]
                )
            );
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'cropeye' || valueType === 'cropeye2' || valueType === 'sensor_formdata') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'table') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'graph') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
                graphType: ['line', Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l46');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l37';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'graph_range') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
                graphType: ['line', Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l46');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l37';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'graph_percentage') {
                this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.widgetForm.addControl('graphType', new UntypedFormControl('radialBar'));
                this.sensorForm = this.fb.group({
                    sensorId: [null, Validators.required],
                    graphType: ['radialBar', Validators.required],
                });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l46');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l37';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'image') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'sensor_value') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'map') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l46');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm36';
                this.sizeLarge = 'l46';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'images_list') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'bins') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l26');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l26';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'activities') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.sensorForm = this.fb.group({
                sensorId: [null],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m36');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = '326';
                this.sizeLarge = 'l36';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'device_info') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'devices') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m46');
                this.widgetForm.get('sizeLarge').setValue('l66');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm46';
                this.sizeLarge = 'l66';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'weather_forecast') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.sensorForm = this.fb.group({
                sensorId: [null],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
                this.sensorGroupview = null;
            }
        } else if (valueType === 'overview') {
          this.widgetForm.controls.soil &&
              this.widgetForm.removeControl('soil');
          this.sensorForm = this.fb.group({
              sensorId: [null, Validators.required],
          });
          if (
              this.widgetForm.get('bgColor').value == null ||
              this.widgetForm.get('bgColor').value == ''
          ) {
              this.widgetForm.get('bgColor').setValue('bg_white');
              this.widgetForm.get('sizeSmall').setValue('s26');
              this.widgetForm.get('sizeMedium').setValue('m26');
              this.widgetForm.get('sizeLarge').setValue('l46');
              this.widgetForm.get('order').setValue(1);
              this.order = 1000;
              this.bgColor = 'bg_white';
              this.sizeSmall = 's26';
              this.sizeMedium = 'm26';
              this.sizeLarge = 'l46';
              this.sensorGroupview = null;
            }
        }
        else if (valueType === 'fungal_alert') {
            if(this.filteredDiseases) {
            this.cropService.getDiseases({type: 'fungal'}).subscribe(data => {
                if(this.newDisease) {
                    this.filteredDiseases = [this.newDisease, ...data.body.results];
                  } else {
                    this.filteredDiseases = data.body.results;
                  }
                
                this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextToken) this.getMore = true;
            })}
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s25');
                this.widgetForm.get('sizeMedium').setValue('m35');
                this.widgetForm.get('sizeLarge').setValue('l35');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's25';
                this.sizeMedium = 'm35';
                this.sizeLarge = 'l35';
                this.sensorGroupview = null;
            }
          }
          else if (valueType === 'pathogen_alert') {
            if(this.filteredDiseases) {
                this.cropService.getDiseases({type: 'viral'}).subscribe(data => {
                    if(this.newDisease) {
                        this.filteredDiseases = [this.newDisease, ...data.body.results];
                      } else {
                        this.filteredDiseases = data.body.results;
                      }
                    
                    this.nextToken = data.body.next
                    ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                    : null;
                    if(this.nextToken) this.getMore = true;
                })}
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s25');
                this.widgetForm.get('sizeMedium').setValue('m35');
                this.widgetForm.get('sizeLarge').setValue('l35');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's25';
                this.sizeMedium = 'm35';
                this.sizeLarge = 'l35';
                this.sensorGroupview = null;
            }
          }
          else if (valueType === 'pest_alert') {
            if(this.filteredDiseases) {
                this.cropService.getDiseases({type: 'insect'}).subscribe(data => {
                    if(this.newDisease) {
                        this.filteredDiseases = [this.newDisease, ...data.body.results];
                      } else {
                        this.filteredDiseases = data.body.results;
                      }
                    
                    this.nextToken = data.body.next
                    ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                    : null;
                    if(this.nextToken) this.getMore = true;
                })}
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s25');
                this.widgetForm.get('sizeMedium').setValue('m35');
                this.widgetForm.get('sizeLarge').setValue('l35');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's25';
                this.sizeMedium = 'm35';
                this.sizeLarge = 'l35';
                this.sensorGroupview = null;
            }
          }
          else if (valueType === 'herbicide') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s24');
                this.widgetForm.get('sizeMedium').setValue('m34');
                this.widgetForm.get('sizeLarge').setValue('l34');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's24';
                this.sizeMedium = 'm34';
                this.sizeLarge = 'l34';
                this.sensorGroupview = null;
            }
          }
        else {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
                graphType: [null, Validators.required],
            });
        }
    }
    
    onScrollImages(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          console.log("End");
          this.scrolledDown();
        }
    }

    onScrollDevices(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          console.log("End");
          this.scrolledDevices();
        }
    }

      scrolledDown() {
        console.log('scrolled');
        let type = '';
        if(this.selectedtype == 'fungal_alert') {
            type = 'fungal'
        } else if(this.selectedtype == 'pathogen_alert') {
            type = 'viral'
        } else {
            type = 'insect'
        }
        if(this.getMore) {
            this.nextToken &&
              this.subscriptions.push(this.cropService.getDiseases(this.nextToken).subscribe((data) => {
                // console.log(data);
                if(this.filteredDiseases) {
                  this.filteredDiseases = [...this.filteredDiseases, ...data.body.results];
                } else {  
                  this.filteredDiseases = data.body.results;
                }
                if(data.body.next == null) {
                  this.getMore = false;
                  return
                } else {
                  const url = data.body.next.split('?')
                  const urlParams = new URLSearchParams(url[1]);
                  const entries = urlParams.entries();
                  const params = this.paramsToObject(entries);
                  if(this.nextToken.offset != params['offset']) {
                  this.nextToken = {offset: params['offset'], limit: params['limit'], type: type, search: params['search']};
                  } else {
                    return
                  }
                }
              }));
            } else {
              return
            }
      }

      scrolledActivities() {
        console.log('scrolled')
        if(this.getMore) {
            this.nextActivitiesToken &&
              this.subscriptions.push(this.cropService.getDiseases(this.nextActivitiesToken).subscribe((data) => {
                // console.log(data);
                if(this.activities) {
                  this.activities = [...this.activities, ...data.body.results];
                } else {
                  this.activities = data.body.results;
                }
                if(data.body.next == null) {
                  this.getMore = false;
                  return
                } else {
                  const url = data.body.next.split('?')
                  const urlParams = new URLSearchParams(url[1]);
                  const entries = urlParams.entries();
                  const params = this.paramsToObject(entries);
                  if(this.nextActivitiesToken.offset != params['offset']) {
                  this.nextActivitiesToken = {offset: params['offset'], limit: params['limit'], search: params['search']};
                  } else {
                    return
                  }
                }
              }));
            } else {
              return
            }
      }

      scrolledDevices() {
        console.log('scrolled')
        if(this.getMore) {
            this.nextDevicesToken &&
              this.subscriptions.push(this.objectService.getDevices(this.nextDevicesToken).subscribe((data) => {
                // console.log(data);
                if(this.devices) {
                  this.devices = [...this.devices, ...data.body.results];
                } else {
                  this.devices = data.body.results;
                }
                if(data.body.next == null) {
                  this.getMore = false;
                  return
                } else {
                  const url = data.body.next.split('?')
                  const urlParams = new URLSearchParams(url[1]);
                  const entries = urlParams.entries();
                  const params = this.paramsToObject(entries);
                  if(this.nextDevicesToken.offset != params['offset']) {
                  this.nextDevicesToken = {offset: params['offset'], limit: params['limit'], search: params['search']};
                  } else {
                    return
                  }
                }
              }));
            } else {
              return
            }
      }

      public filterDeviceSensors(sensors) {
        const parent = sensors;
        const objects = Object.values(this.objects);
        const unitTypes = Object.values(this.unitTypes);
        const devices = Object.values(this.devices);
      
        const result = parent.map((item) => {
          return {
            ...item,
            objects: objects.filter((el) => el.id === item.sensorRef.object_id),
          };
        });
      
        const result1 = result.map((item) => {
          return {
            ...item,
            unitTypes: unitTypes.filter((el) => el.id === item.sensorRef.unit_type),
          };
        });
      
        const result2 = result1.map((item) => {
          const device = devices.find((el) => el.id === item.device); // Find the device object
          return {
            ...item,
            fullDevice: device ? device : null,
          };
        });
      
        const groupedSensors = devices
        .map(device => {
        const sensorsForDevice = result2.filter(sensor => sensor.device === device.id);
        return sensorsForDevice.length > 0 ? { device: device, data: sensorsForDevice } : null;
        })
        .filter(group => group !== null);
        this.sensorGroup = groupedSensors;
          console.log(this.sensorGroup);
      }

      public filterCoordSensorList(sensors) {
        const parent = sensors;
        const objects = Object.values(this.objects);
        const unitTypes = Object.values(this.unitTypes);
        const devices = Object.values(this.devices);
      
        const result = parent.map((item) => {
          return {
            ...item,
            objects: objects.filter((el) => el.id === item.object_id),
          };
        });
      
        const result1 = result.map((item) => {
          return {
            ...item,
            unitTypes: unitTypes.filter((el) => el.id === item.unit_type),
          };
        });
      
        const result2 = result1.map((item) => {
          const device = devices.find((el) => el.id === item.device); // Find the device object
          return {
            ...item,
            fullDevice: device ? device : null,
          };
        });
      
        const groupedSensors = devices
        .map(device => {
        const sensorsForDevice = result2.filter(sensor => sensor.device === device.id);
        return sensorsForDevice.length > 0 ? { device: device, data: sensorsForDevice } : null;
        })
        .filter(group => group !== null);
        this.coordSensorGroup = groupedSensors;
          console.log(this.coordSensorGroup);
      }

      public filterPercentageSensorList(sensors) {
        const parent = sensors;
        const objects = Object.values(this.objects);
        const unitTypes = Object.values(this.unitTypes);
        const devices = Object.values(this.devices);
      
        const result = parent.map((item) => {
          return {
            ...item,
            objects: objects.filter((el) => el.id === item.object_id),
          };
        });
      
        const result1 = result.map((item) => {
          return {
            ...item,
            unitTypes: unitTypes.filter((el) => el.id === item.unit_type),
          };
        });
      
        const result2 = result1.map((item) => {
          const device = devices.find((el) => el.id === item.device); // Find the device object
          return {
            ...item,
            fullDevice: device ? device : null,
          };
        });
      
        const groupedSensors = devices
        .map(device => {
        const sensorsForDevice = result2.filter(sensor => sensor.device === device.id);
        return sensorsForDevice.length > 0 ? { device: device, data: sensorsForDevice } : null;
        })
        .filter(group => group !== null);
        this.percentageSensorGroup = groupedSensors;
          console.log(this.percentageSensorGroup);
      }

      areAllChildrenSelected(group: any): boolean {
        return group.data.every(
          (sensor: any) => this.itemList.some((item: any) => item.sensor.id === sensor.id)
        );
      }
      
      onOptionSelected(event: MatSelectChange, limit?: number) {
        console.log('Limit is '+ limit);
        const selectedSensorId = event.value;
        if (this.itemList.some(item => item.sensor.id === selectedSensorId)) {
            this.itemList = this.itemList.filter(item => item.sensor.id !== selectedSensorId);
        } else {
            this.addItem();
            this.sensorGroup.forEach((group: any) => {
                const allChildrenSelected = group.data.every(
                  (sensor: any) => this.itemList.some((item: any) => item.sensor.id === sensor.id)
                );
                group.isAllChildrenSelected = allChildrenSelected;
              });
        }
        console.log(this.itemList);
        this.sensorForm.get('sensorId').reset();
      }

      onCoordOptionSelected(event: MatSelectChange, limit?: number) {
        console.log('Limit is '+ limit);
        const selectedSensorId = event.value;
        if (this.itemList.some(item => item.sensor.id === selectedSensorId)) {
            this.itemList = this.itemList.filter(item => item.sensor.id !== selectedSensorId);
        } else {
            this.addItem();
            this.coordSensorGroup.forEach((group: any) => {
                const allChildrenSelected = group.data.every(
                  (sensor: any) => this.itemList.some((item: any) => item.sensor.id === sensor.id)
                );
                group.isAllChildrenSelected = allChildrenSelected;
              });
        }
        console.log(this.itemList);
        this.sensorForm.get('sensorId').reset();
      }

    public filterSensors(lotId) {
        if (lotId) {
            const filteredDevices = this.devices.filter(device =>
                device.lots.some(lot => lot === lotId)
            );
            const filteredSensors = this.sensors.filter(sensor =>
                filteredDevices.some(device => device.id === sensor.device)
            );
            this.filteredSensors = filteredSensors;
            this.filterDeviceSensors(filteredSensors);
        }
    }

    public filterCoordSensors(lotId) {
        if (lotId) {
            const filteredDevices = this.devices.filter(device =>
                device.lots.some(lot => lot === lotId)
            );
            const filteredCoordSensors = this.coordSensors.filter(sensor =>
                filteredDevices.some(device => device.id === sensor.device)
            );
            this.filteredCoordSensors = filteredCoordSensors;
            this.filterCoordSensorList(filteredCoordSensors);
        }
    }

    public filterPercentageSensors(lotId) {
        if (lotId) {
            const filteredDevices = this.devices.filter(device =>
                device.lots.some(lot => lot === lotId)
            );
            const filteredPercentageSensors = this.percentageSensors.filter(sensor =>
                filteredDevices.some(device => device.id === sensor.device)
            );
            this.filteredPercentageSensors = filteredPercentageSensors;
            this.filterPercentageSensorList(filteredPercentageSensors);
        }
    }
    
    selectAllChildren(event: MatCheckboxChange, group: any) {
          const allChildrenSelected = group.data.every(
            (sensor: any) => this.itemList.some((item: any) => item.sensor.id === sensor.id)
          );
          if (allChildrenSelected) {
            group.data.forEach((sensor: any) => {
              this.itemList = this.itemList.filter((item: any) => item.sensor.id !== sensor.id);
            });
          } else {
            group.data.forEach((sensor: any) => {
              const selectedSensor = this.sensors.find((s: any) => s.id === sensor.id);
              const item = { sensor: selectedSensor };
              this.itemList.push(item);
            });
          }
          event.source.checked = !allChildrenSelected;
      }

    public submitWidget() {
        const { lot, name, type, soil, name_t, graphType, sensorGroupview } = this.widgetForm.value;
        const sizeClass =
            this.sizeSmall + ' ' + this.sizeMedium + ' ' + this.sizeLarge;
        const bgColor = this.bgColor;
        const order = this.order;
        const background = this.widgetImages;
        const disease = this.disease;
        const activity = this.activity;
        const device = this.device;
        const lots = this.widgetLots;
        const mapType = this.mapType;
        const form = this.form;
        const show_min_max = this.widget ? this.widget.settings['show_min_max'] ? this.widget.settings['show_min_max'] : false : false;
        const show_legend = this.widget ? this.widget.settings['show_legend'] ? this.widget.settings['show_legend'] : false : false
        // const sensorGroupview = this.sensorGroupview;
        
        console.log(this.widgetForm.value);
        // if (this.widgetForm.invalid) return;
        if (
            (type === 'graph' || type === 'table' || type === 'graph_percentage' || type === 'graph_range') &&
            this.itemList.length === 0
        )
            return;

        let itemList = [];
        
        if(type === 'graph') {
           itemList = this.itemList.map(obj => ({ id: obj.sensor.id, graphType: obj.graphType, color: obj.color}));
        } else if(type === 'sensor_value' || type === 'image') {
           itemList = this.itemList.map(obj => ({ id: obj.sensor.id, css: this.css }));
        } else {
            itemList = this.itemList.map(obj => ({ id: obj.sensor.id}));
        }

        console.log('sensors', itemList);
        let w_type = null;
        this.widgetTypes.forEach(type => {
            if(this.widgetForm.value.type && this.widgetForm.value.type === type.uniqueid) {
                w_type = type.id
            }
        });
        const widget = {
            settings: {
                streams: type === 'soil' ? soil : itemList,
                background,
                disease, 
                device,
                activity,
                lots,
                mapType,
                form,
                graphType,
                sensorGroupview,
                show_min_max,
                show_legend
            },
            sizeSmall: this.sizeSmall,
            sizeMedium: this.sizeMedium,
            sizeLarge: this.sizeLarge,
            bgColor,
            name,
            name_t,
            contentType: 'lot',
            objectId: lot ? lot : 0,
            type: w_type,
            view: this.view,
            order
        };
        // this.resetForms();
        this.widget
            ? this.updateWidget.emit({ id: this.widget.id, widget })
            : this.addWidget.emit(widget);

        this.css = null;
        this.sensorCss = null;
        this.config = null;
    }

    public resetForms(): void {
        this.widgetForm.removeControl('soil');
        this.widgetFormDirective.resetForm();
        this.itemList = [];
        this.sensorForm && this.sensorForm.reset();
    }

    public addActivity() {
        console.log('activity add clicked');
    }

      public submitConfig() {
        console.log(this.configForm.value);
        console.log(this.imgPath);
        const css = {
            top: this.configForm.value.top+'%',
            left: this.configForm.value.left+'%',
            color: this.configForm.value.color,
            transform: `translate(-${this.configForm.value.left}%, -${this.configForm.value.top}%)`,
            font_size: this.configForm.value.font_size+'px'
        }
        console.log(css);
        this.css = css;
        this.sensorCss = css;
        if(this.widget) {
            console.log(this.widget.settings['streams']);
            this.widget.settings['streams'][0]['css'] = css;
        } else {
            this.tempSettings = css;
        }
        this.configModal.hideModal();
      }

      public submitGraphConfig() {
        console.log(this.graphConfigForm.value);
        this.graphItem['color'] = this.graphConfigForm.value.color;
        this.graphItem['graphType'] = this.graphConfigForm.value.graphType;
        console.log(this.graphItem);
        console.log(this.itemList)
        this.graphConfigModal.hideModal();
      }

      public showConfigModal(): void {
        this.configModal.showModal();
        if(this.widget) {
            this.config = this.widget.settings['streams'][0]['css'];
            console.log(this.config);
        }
        this.initConfigForm();
      }
      
      public initGraphConfigModalButtons(): void {
        const _this: FapAddEditWidgetComponent = this;
      
        this.graphConfigModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.submitGraphConfig();
            },
            text: this.translateService.instant('save')
        };
      
        this.graphConfigModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.graphConfigModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
      }

      public initConfigModalButtons(): void {
        const _this: FapAddEditWidgetComponent = this;
      
        this.configModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.submitConfig();
            },
            text: this.translateService.instant('save')
        };
      
        this.configModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.configModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
      }

      addImage(ev) {
        console.log(ev)
        const imgObj = {
            id: ev.value.id,
            file: ev.value.file
        }
        this.widgetImages = imgObj;
        this.imgPath = imgObj.file;
        this.widgetForm.get('background').setValue(this.widgetImages);
      }
      
        paramsToObject(entries) {
          const result = {}
          for(const [key, value] of entries) {
            result[key] = value;
          }
          console.log(result);
          return result;
        }

        editWigetValue() {
            this.editForm.emit(this.widget.id);
        }

        changeItemColor(color) {
            console.log(color);
            console.log(this.graphItem);
            this.color = color.value;
        }
    
        getNumberArray(dynamicNumber: number): number[] {
            return Array.from({ length: dynamicNumber }, (_, index) => index + 1);
        }

        dropItem(event: CdkDragDrop<any[]>) {
            moveItemInArray(this.itemList, event.previousIndex, event.currentIndex);
          }

    changeGroup(id) {
        console.log(id);
    }

    openConfigModal() {
        this.sensorConfigModal.showModal();
    }
}
