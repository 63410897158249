import { NoteModel } from "../../../../../core/models/notes/note.model";
import { NotesInterface } from "../../../../../core/interfaces/notes/notes.interface";
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from "@angular/core";
import { WeatherModel } from "../../../../../core/models/widget/weather/weather.model";
import { FarmModel } from "../../../../../core/models/farm/farm.model";
import { WidgetsService } from "../../../../../core/services/api/widgets/widgets.service";
import { Subscription } from "rxjs";
import { CompanyService } from "../../../../../core/services/api/company/company.service";
import { PersonModel } from "../../../../../core/models/person/person.model";
import { GlobalRegistryService } from "../../../../../core/global-registry/global-registry.service";
import { NoteTypeModel } from "../../../../../core/models/type/note-type.model";

@Component({
  selector: "fap-notes-layout",
  templateUrl: "./fap_notes-layout.component.html",
  styleUrls: ["./fap_notes-layout.component.scss"],
})
export class FapNotesLayoutComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public farms: Array<FarmModel>;

  @Input()
  public notes: Array<NoteModel> = [];

  @Input()
  public types: Array<NoteTypeModel> = [];

  @Output()
  public addNote: EventEmitter<NotesInterface> = new EventEmitter();

  @Output()
  public updateNote: EventEmitter<NotesInterface> = new EventEmitter();

  @Output()
  public deleteNote: EventEmitter<number> = new EventEmitter();

  @Input()
  public farmForecastMapping: { [key: number]: WeatherModel } = {};

  public displayedColumns: string[] = ["type", "date", "info", "actions"];
  public selectedNote: NoteModel;
  public isLoading = false;
  public subscription: Subscription = new Subscription();
  @Input() public persons: Array<PersonModel>;
  
  constructor(public widgetService: WidgetsService, public companyService: CompanyService, public globalRegistry: GlobalRegistryService) {}

  ngOnInit() {
    // this.isLoading = true
    // console.log(this.notes);
    this.widgetService.setSize(6);
  }

  ngOnChanges() {
    if(this.notes != undefined) {
      // this.isLoading = false
    }
  }

  deleteNotes(event) {
    this.deleteNote.emit(event);
  }

  ngOnDestroy(): void {
    this.widgetService.setSize(6);
  }

}
